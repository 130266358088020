import { ZodError, ZodIssueCode, z } from 'zod';

export const isZodError = (error: any): error is ZodError => {
  return error instanceof Error && error.name === 'ZodError';
};

export const getFirstMessageFromZodError = (error: any) => {
  if (!isZodError(error)) {
    return undefined;
  }
  return error.issues?.[0].message;
};

export type Validation =
  | {
      isValid: false;
      message: string;
    }
  | {
      isValid: true;
    };

export const toSuperRefinement =
  <T>(validator: (v: T) => Validation) =>
  (v: T, ctx: z.RefinementCtx) => {
    const validation = validator(v);
    if (validation.isValid === false) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        message: validation.message,
      });
    }
  };
