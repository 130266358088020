import type { ValueOf } from '@daangn/car-utils/type';

import { z } from 'zod';

import { ReportItemStateSchema } from './ReportItemState';

export const ReportBaseInfoGearType = {
  Auto: 'Auto',
  Manual: 'Manual',
} as const;
export type ReportBaseInfoGearType = ValueOf<typeof ReportBaseInfoGearType>;
export const ReportBaseInfoGearTypeText = {
  [ReportBaseInfoGearType.Auto]: '자동 (A/T)',
  [ReportBaseInfoGearType.Manual]: '수동 (M/T)',
};

export const ReportBaseInfoFuelType = {
  Gasoline: 'Gasoline',
  GasolineHybrid: 'GasolineHybrid',
  LPG: 'LPG',
  LPGHybrid: 'LPGHybrid',
  Diesel: 'Diesel',
  DieselHybrid: 'DieselHybrid',
  Electric: 'Electric',
  HydrogenElectric: 'HydrogenElectric',
} as const;
export type ReportBaseInfoFuelType = ValueOf<typeof ReportBaseInfoFuelType>;
export const ReportBaseInfoFuelTypeText = {
  Gasoline: '가솔린',
  GasolineHybrid: '가솔린 하이브리드',
  LPG: 'LPG',
  LPGHybrid: 'LPG 하이브리드',
  Diesel: '경유',
  DieselHybrid: '경유 하이브리드',
  Electric: '전기',
  HydrogenElectric: '수소 전기',
};

export const ReportBaseInfoPowerSteeringType = {
  Electric: 'Electric',
  Hydraulic: 'Hydraulic',
} as const;
export type ReportBaseInfoPowerSteeringType = ValueOf<typeof ReportBaseInfoPowerSteeringType>;
export const ReportBaseInfoPowerSteeringTypeText: Record<ReportBaseInfoPowerSteeringType, string> =
  {
    Electric: '전동식',
    Hydraulic: '유압식',
  };

export const ReportBaseInfoPowertrainLayout = {
  FF: 'FF',
  FR: 'FR',
  MR: 'MR',
  RR: 'RR',
  AWD: 'AWD',
} as const;
export type ReportBaseInfoPowertrainLayout = ValueOf<typeof ReportBaseInfoPowertrainLayout>;
export const ReportBaseInfoPowertrainLayoutText: Record<ReportBaseInfoPowertrainLayout, string> = {
  FF: 'FF',
  FR: 'FR',
  MR: 'MR',
  RR: 'RR',
  AWD: 'AWD',
} as const;

export type MechanicReportBaseInfo = z.input<typeof MechanicReportBaseInfoSchema>;
export const MechanicReportBaseInfoSchema = z.object({
  driveDistance: z.number().positive(),
  tuningHistory: ReportItemStateSchema,
  powertrainLayout: z.nativeEnum(ReportBaseInfoPowertrainLayout),
  powerSteeringType: z.nativeEnum(ReportBaseInfoPowerSteeringType),
  gearType: z.nativeEnum(ReportBaseInfoGearType),
  fuelType: z.nativeEnum(ReportBaseInfoFuelType),
});
