import type { ValueOf } from '@daangn/car-utils/type';

export const AuctionSellerDocumentType = {
  // 세금계산서를 발행하는 간이과세/일반과세/면세 개인사업자 중 사업용 차량으로 사용한 경우
  BUSINESS_USE_INDIVIDUAL_BUSINESS: 'BUSINESS_USE_INDIVIDUAL_BUSINESS',
  // 법인사업자
  CORPORATION_BUSINESS: 'CORPORATION_BUSINESS',
  // 일반 개인
  INDIVIDUAL: 'INDIVIDUAL',
  // 세금계산서를 발행하는 간이과세/일반과세/면세 개인사업자 중 비사업용 차량으로 사용하였으며 복식부기장부를 작성하는 경우
  NON_BUSINESS_USE_DOUBLE_ENTRY_INDIVIDUAL_BUSINESS:
    'NON_BUSINESS_USE_DOUBLE_ENTRY_INDIVIDUAL_BUSINESS',
  // 세금계산서를 발행하는 간이과세/일반과세/면세 개인사업자 중 비사업용 차량으로 사용하였으며 간편장부를 작성하는 경우
  NON_BUSINESS_USE_SIMPLE_ENTRY_INDIVIDUAL_BUSINESS:
    // 세금계산서를 발행하지 않는 간이과세 개인사업자
    'NON_BUSINESS_USE_SIMPLE_ENTRY_INDIVIDUAL_BUSINESS',
  SIMPLE_TAX_INDIVIDUAL_BUSINESS: 'SIMPLE_TAX_INDIVIDUAL_BUSINESS',
} as const;

export type AuctionSellerDocumentType = ValueOf<typeof AuctionSellerDocumentType>;
