import type { ValueOf } from '@daangn/car-utils/type';

export const ImageType = {
  UNKNOWN: 'UNKNOWN',
  EXTERIOR: 'EXTERIOR',
  INTERIOR: 'INTERIOR',
  DOCUMENT: 'DOCUMENT',
} as const;

export type ImageType = ValueOf<typeof ImageType>;
