import { z } from 'zod';

import { ImageFirstReportItemSchema } from './ReportItemState';
export type InteriorConditionDashboardPart = z.input<typeof InteriorConditionDashboardPartSchema>;
const InteriorConditionDashboardPartSchema = z.object({
  dashboard: ImageFirstReportItemSchema,
});
export type InteriorConditionDoorTrimPart = z.input<typeof InteriorConditionDoorTrimPartSchema>;
const InteriorConditionDoorTrimPartSchema = z.object({
  doorTrim: ImageFirstReportItemSchema,
});
export type InteriorConditionDriverSeatMatPart = z.input<
  typeof InteriorConditionDriverSeatMatPartSchema
>;
const InteriorConditionDriverSeatMatPartSchema = z.object({
  driverSeatMat: ImageFirstReportItemSchema,
});
export type InteriorConditionHandlePart = z.input<typeof InteriorConditionHandlePartSchema>;
const InteriorConditionHandlePartSchema = z.object({
  handle: ImageFirstReportItemSchema,
});
export type InteriorConditionHeadlinerPart = z.input<typeof InteriorConditionHeadlinerPartSchema>;
const InteriorConditionHeadlinerPartSchema = z.object({
  roof: ImageFirstReportItemSchema,
  pillarCover: ImageFirstReportItemSchema,
});
export type InteriorConditionSeatPart = z.input<typeof InteriorConditionSeatPartSchema>;
const InteriorConditionSeatPartSchema = z.object({
  row1: ImageFirstReportItemSchema,
  row2: ImageFirstReportItemSchema,
  row3: ImageFirstReportItemSchema,
});
export type InteriorConditionCenterConsolePart = z.input<
  typeof InteriorConditionCenterConsolePartSchema
>;
const InteriorConditionCenterConsolePartSchema = z.object({
  centerConsole: ImageFirstReportItemSchema,
});
export type InteriorConditionCenterFasciaPart = z.input<
  typeof InteriorConditionCenterFasciaPartSchema
>;
const InteriorConditionCenterFasciaPartSchema = z.object({
  centerFascia: ImageFirstReportItemSchema,
});
export type InteriorConditionChecklist = z.input<typeof InteriorConditionChecklistSchema>;
export const InteriorConditionChecklistSchema = z.object({
  dashboard: InteriorConditionDashboardPartSchema,
  centerFascia: InteriorConditionCenterFasciaPartSchema,
  centerConsole: InteriorConditionCenterConsolePartSchema,
  seat: InteriorConditionSeatPartSchema,
  headliner: InteriorConditionHeadlinerPartSchema,
  handle: InteriorConditionHandlePartSchema,
  doorTrim: InteriorConditionDoorTrimPartSchema,
  driverSeatMat: InteriorConditionDriverSeatMatPartSchema,
});
