import { toSuperRefinement } from '@daangn/car-utils/zod';
import { z } from 'zod';

import { ReportConsumableItemSchema } from '../ReportItemState';
import {
  brakePadWearMax,
  tireTreadDepthDecimalPlaces,
  tireTreadDepthMax,
  toDateStringFromYearAndWeek,
  validateDecimalPlaces,
  validateYearAndWeek,
} from './utils';
export type TireChecklist = z.input<typeof TireChecklistSchema>;

export const TireChecklistSchema = z.object({
  productionDate: z.object({
    front: z
      .string()
      .superRefine(toSuperRefinement(validateYearAndWeek))
      .transform(toDateStringFromYearAndWeek),
  }),
  brakePadWear: z.object({
    front: z.number().positive().max(brakePadWearMax),
    rear: z.number().positive().max(brakePadWearMax).optional(),
  }),
  tireTreadDepth: z.object({
    frontDriverSeat: z
      .number()
      .min(0)
      .max(tireTreadDepthMax)
      .superRefine(toSuperRefinement(validateDecimalPlaces(tireTreadDepthDecimalPlaces))),
    rearDriverSeat: z
      .number()
      .min(1)
      .max(tireTreadDepthMax)
      .superRefine(toSuperRefinement(validateDecimalPlaces(tireTreadDepthDecimalPlaces))),
    frontPassengerSeat: z
      .number()
      .min(1)
      .max(tireTreadDepthMax)
      .superRefine(toSuperRefinement(validateDecimalPlaces(tireTreadDepthDecimalPlaces))),
    rearPassengerSeat: z
      .number()
      .min(1)
      .max(tireTreadDepthMax)
      .superRefine(toSuperRefinement(validateDecimalPlaces(tireTreadDepthDecimalPlaces))),
  }),
  tireCondition: z.object({
    front: ReportConsumableItemSchema,
    rear: ReportConsumableItemSchema,
  }),
});
