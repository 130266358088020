/**
 * @generated SignedSource<<4283d4debc877f2f91680e909fb79497>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type FeatureFlagContextQuery$variables = Record<PropertyKey, never>;
export type FeatureFlagContextQuery$data = {
  readonly features: ReadonlyArray<{
    readonly enabled: boolean;
    readonly name: string;
  }>;
};
export type FeatureFlagContextQuery = {
  response: FeatureFlagContextQuery$data;
  variables: FeatureFlagContextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Feature",
    "kind": "LinkedField",
    "name": "features",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeatureFlagContextQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FeatureFlagContextQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b1ad90f2a2e06d6b2eacd677588fd3bb",
    "id": null,
    "metadata": {},
    "name": "FeatureFlagContextQuery",
    "operationKind": "query",
    "text": "query FeatureFlagContextQuery {\n  features {\n    enabled\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "5311f1b553055a9ff34183d931d83be6";

export default node;
