import { circle, point } from '@turf/turf';

import type { Coordinates } from './Coordinates';

export const getMinimumBoundaryPolygonByDistance = (
  coordinates: Coordinates,
  distanceKm: number
) => {
  const turfPoint = point([Number(coordinates.longitude), Number(coordinates.latitude)]);
  const result = circle(turfPoint, distanceKm, {
    units: 'kilometers',
    steps: 12,
  });
  return result;
};
