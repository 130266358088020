import { z } from 'zod';

import { ImageFirstReportItemSchema, ReportItemStateSchema } from './ReportItemState';

export type ExteriorRoofPart = z.input<typeof ExteriorRoofPartSchema>;
export const ExteriorRoofPartSchema = z.object({
  roof: ImageFirstReportItemSchema,
});

export type ExteriorMufflerPart = z.input<typeof ExteriorMufflerPartSchema>;
export const ExteriorMufflerPartSchema = z.object({
  muffler: ImageFirstReportItemSchema,
});

export type ExteriorFrontPart = z.input<typeof ExteriorFrontPartSchema>;
export const ExteriorFrontPartSchema = z.object({
  frontBumper: ImageFirstReportItemSchema,
  frontBumperMolding: ReportItemStateSchema,
  grille: ImageFirstReportItemSchema,
  headlamp: ImageFirstReportItemSchema,
  turnSignal: ImageFirstReportItemSchema,
  frontWindshield: ImageFirstReportItemSchema,
  tinting: ImageFirstReportItemSchema,
  hood: ImageFirstReportItemSchema,
});
export type ExteriorRearPart = z.input<typeof ExteriorRearPartSchema>;
export const ExteriorRearPartSchema = z.object({
  rearBumper: ImageFirstReportItemSchema,
  tailLamp: ImageFirstReportItemSchema,
  rearWindowTinting: ImageFirstReportItemSchema,
  rearBumperMolding: ReportItemStateSchema,
  trunkLid: ReportItemStateSchema,
});
export type ExteriorSidePart = z.input<typeof ExteriorSidePartSchema>;
export const ExteriorSidePartSchema = z.object({
  fender: ImageFirstReportItemSchema,
  sideMirror: ReportItemStateSchema,
  frontWheel: ReportItemStateSchema,
  rearWheel: ReportItemStateSchema,
  frontDoor: ImageFirstReportItemSchema,
  rearDoor: ImageFirstReportItemSchema,
  sideSealPanel: ImageFirstReportItemSchema,
  quarterPanel: ImageFirstReportItemSchema,
  aPillar: ImageFirstReportItemSchema,
  bPillar: ImageFirstReportItemSchema,
  cPillar: ImageFirstReportItemSchema,
  dPillar: ImageFirstReportItemSchema,
  doorMolding: ReportItemStateSchema,
  doorGarnish: ReportItemStateSchema,
  fenderGarnish: ReportItemStateSchema,
  quarterPanelGarnish: ReportItemStateSchema,
});
export type ExteriorChecklist = z.input<typeof ExteriorChecklistSchema>;
export const ExteriorChecklistSchema = z.object({
  front: ExteriorFrontPartSchema,
  right: ExteriorSidePartSchema,
  rear: ExteriorRearPartSchema,
  left: ExteriorSidePartSchema,
  roof: ExteriorRoofPartSchema,
  muffler: ExteriorMufflerPartSchema,
});
