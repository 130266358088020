import type { ValueOf } from '@daangn/car-utils/type';

export const CompanyOrigin = {
  Korean: 'KOREAN',
  Foreign: 'FOREIGN',
} as const;

export const CompanyOriginText = {
  [CompanyOrigin.Korean]: '국산차',
  [CompanyOrigin.Foreign]: '수입차',
};
export type CompanyOrigin = ValueOf<typeof CompanyOrigin>;
