import type { ValueOf } from '@daangn/car-utils/type';

import { objectValues } from '@toss/utils';

export const ConvenienceOption = {
  BLUETOOTH: 'BLUETOOTH',
  NAVIGATION: 'NAVIGATION',
  SMART_KEY: 'SMART_KEY',
  CRUISE_CONTROL: 'CRUISE_CONTROL',
  AUTO_AIR_CONDITIONER: 'AUTO_AIR_CONDITIONER',
  AUTO_WIPER: 'AUTO_WIPER',
  HUD: 'HUD',
  EPB: 'EPB',
} as const;
export const ConvenienceOptionNames = objectValues(ConvenienceOption);
export type ConvenienceOption = ValueOf<typeof ConvenienceOption>;
