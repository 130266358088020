import { objectEntries } from '@toss/utils';
import { camelCase } from 'es-toolkit';

export const filterNil = <T extends Record<string, any>>(obj: T) => {
  const entries = Object.entries(obj).filter(([, v]) => v != null);

  return Object.fromEntries(entries) as { [key in keyof T]: NonNullable<T[key]> };
};

export const filterNilRecursive = <T extends Record<string, any>>(obj: T): T => {
  const entries = Object.entries(obj)
    .filter(([, v]) => v != null)
    .map(([k, v]) => [k, v instanceof Object ? filterNilRecursive(v) : v]);

  return Object.fromEntries(entries) as { [K in keyof T]: NonNullable<T[K]> };
};

export const getInvertedMap = (obj: Record<number, null | number>) =>
  Object.fromEntries(
    objectEntries(obj)
      .filter(([, value]) => !!value)
      .map(([key, value]) => [Number(value), Number(key)])
  );

export const nullify = (arr: any[]) => {
  if (!arr.length) {
    return null;
  }
  return arr;
};

export const convertToCamelCase = <T extends { [key: string]: any }>(obj: T): T => {
  const entries = Object.entries(obj).map(([k, v]) => [camelCase(k), v]);

  return Object.fromEntries(entries);
};

export function insertAt<T>(array: T[], index: number, newItem: T): T[] {
  return [...array.slice(0, index), newItem, ...array.slice(index)];
}
