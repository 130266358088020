import type { ValueOf } from '@daangn/car-utils/type';

import { z } from 'zod';

import { ReportImageSchema } from './ReportItemState';

export const MechanicReportRejectSelection = {
  TOTAL_LOSS: 'TOTAL_LOSS',
  FLOODED: 'FLOODED',
  BURNED: 'BURNED',
  VIN_MISMATCH: 'VIN_MISMATCH',
  MOTOR_TYPE_MISMATCH: 'MOTOR_TYPE_MISMATCH',
  FALSIFIED_DRIVE_DISTANCE: 'FALSIFIED_DRIVE_DISTANCE',
  ETC: 'ETC',
} as const;
export type MechanicReportRejectSelection = ValueOf<typeof MechanicReportRejectSelection>;
export const MechanicReportRejectSelectionText = {
  TOTAL_LOSS: '전손',
  FLOODED: '침수',
  BURNED: '화재',
  VIN_MISMATCH: '차대번호 불일치',
  MOTOR_TYPE_MISMATCH: '원동기 형식 불일치',
  FALSIFIED_DRIVE_DISTANCE: '주행거리 조작',
  ETC: '기타',
} as const;
export type MechanicReportRejectState = z.input<typeof MechanicReportRejectStateSchema>;
export const MechanicReportRejectStateSchema = z.object({
  selections: z
    .array(z.nativeEnum(MechanicReportRejectSelection))
    .min(1, { message: '사유를 선택해 주세요.' }),
  comment: z
    .string({ required_error: '사유를 입력해 주세요.' })
    .min(1, { message: '사유를 입력해 주세요.' }),
  imgs: z
    .array(ReportImageSchema, { required_error: '사진을 업로드해 주세요.' })
    .min(1, { message: '사진을 업로드해 주세요.' }),
});
