import { z } from 'zod';

import { ReportItemStateSchema } from './ReportItemState';

export type ManualTransmissionOperationPart = z.input<typeof ManualTransmissionOperationPartSchema>;
export const ManualTransmissionOperationPartSchema = z.object({
  operation: ReportItemStateSchema,
});
export type ManualTransmissionGearShiftPart = z.input<typeof ManualTransmissionGearShiftPartSchema>;
export const ManualTransmissionGearShiftPartSchema = z.object({
  gearShift: ReportItemStateSchema,
});
export type ManualTransmissionOilLeakPart = z.input<typeof ManualTransmissionOilLeakPartSchema>;
export const ManualTransmissionOilLeakPartSchema = z.object({
  oilLeak: ReportItemStateSchema,
});
export type ManualTransmissionChecklist = z.input<typeof ManualTransmissionChecklistSchema>;
export const ManualTransmissionChecklistSchema = z.object({
  operation: ManualTransmissionOperationPartSchema,
  gearShift: ManualTransmissionGearShiftPartSchema,
  oilLeak: ManualTransmissionOilLeakPartSchema,
});
