import { z } from 'zod';

import { ReportItemStateSchema } from './ReportItemState';
export type ChargingPortIsolationPart = z.input<typeof ChargingPortIsolationPartSchema>;
export const ChargingPortIsolationPartSchema = z.object({
  isolation: ReportItemStateSchema,
});
export type BatteryIsolationPart = z.input<typeof BatteryIsolationPartSchema>;
export const BatteryIsolationPartSchema = z.object({
  isolation: ReportItemStateSchema,
});

export type HighVoltageElectricalSystemWirePart = z.input<
  typeof HighVoltageElectricalSystemWirePartSchema
>;
export const HighVoltageElectricalSystemWirePartSchema = z.object({
  wire: ReportItemStateSchema,
});
export type HighVoltageElectricalSystemChecklist = z.input<
  typeof HighVoltageElectricalSystemChecklistSchema
>;
export const HighVoltageElectricalSystemChecklistSchema = z.object({
  chargingPortIsolation: ChargingPortIsolationPartSchema,
  batteryIsolation: BatteryIsolationPartSchema,
  wire: HighVoltageElectricalSystemWirePartSchema,
});
