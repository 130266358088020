import type { ValueOf } from '@daangn/car-utils/type';

import { z } from 'zod';

export const AccidentHistorySelection = {
  Replacement: 'Replacement',
  Welding: 'Welding',
  Bending: 'Bending',
  NoIssues: 'NoIssues',
} as const;
export type AccidentHistorySelection = ValueOf<typeof AccidentHistorySelection>;
export const AccidentHistorySelectionText = {
  Replacement: '교환',
  Welding: '용접',
  Bending: '판금',
  NoIssues: '정상',
} as const;

export type ExternalPanelPart = z.input<typeof ExternalPanelPartSchema>;
export const ExternalPanelPartSchema = z.object({
  leftFender: z.nativeEnum(AccidentHistorySelection),
  rightFender: z.nativeEnum(AccidentHistorySelection),
  leftFrontDoor: z.nativeEnum(AccidentHistorySelection),
  rightFrontDoor: z.nativeEnum(AccidentHistorySelection),
  leftRearDoor: z.nativeEnum(AccidentHistorySelection),
  rightRearDoor: z.nativeEnum(AccidentHistorySelection),
  leftQuarterPanel: z.nativeEnum(AccidentHistorySelection),
  rightQuarterPanel: z.nativeEnum(AccidentHistorySelection),
  hood: z.nativeEnum(AccidentHistorySelection),
  trunkLid: z.nativeEnum(AccidentHistorySelection),
  roofPanel: z.nativeEnum(AccidentHistorySelection),
  radiatorSupport: z.nativeEnum(AccidentHistorySelection),
  leftSideSealPanel: z.nativeEnum(AccidentHistorySelection),
  rightSideSealPanel: z.nativeEnum(AccidentHistorySelection),
});
export type MainFramePart = z.input<typeof MainFramePartSchema>;
export const MainFramePartSchema = z.object({
  frontPanel: z.nativeEnum(AccidentHistorySelection),
  leftInsidePanel: z.nativeEnum(AccidentHistorySelection),
  rightInsidePanel: z.nativeEnum(AccidentHistorySelection),
  leftFrontWheelHouse: z.nativeEnum(AccidentHistorySelection),
  rightFrontWheelHouse: z.nativeEnum(AccidentHistorySelection),
  leftRearWheelHouse: z.nativeEnum(AccidentHistorySelection),
  rightRearWheelHouse: z.nativeEnum(AccidentHistorySelection),
  leftFrontSideMember: z.nativeEnum(AccidentHistorySelection),
  rightFrontSideMember: z.nativeEnum(AccidentHistorySelection),
  leftRearSideMember: z.nativeEnum(AccidentHistorySelection),
  rightRearSideMember: z.nativeEnum(AccidentHistorySelection),
  crossMember: z.nativeEnum(AccidentHistorySelection),
  dashPanel: z.nativeEnum(AccidentHistorySelection),
  floorPanel: z.nativeEnum(AccidentHistorySelection),
  packageTray: z.nativeEnum(AccidentHistorySelection),
  leftPillarPanelA: z.nativeEnum(AccidentHistorySelection),
  leftPillarPanelB: z.nativeEnum(AccidentHistorySelection),
  leftPillarPanelC: z.nativeEnum(AccidentHistorySelection),
  leftPillarPanelD: z.nativeEnum(AccidentHistorySelection),
  rightPillarPanelA: z.nativeEnum(AccidentHistorySelection),
  rightPillarPanelB: z.nativeEnum(AccidentHistorySelection),
  rightPillarPanelC: z.nativeEnum(AccidentHistorySelection),
  rightPillarPanelD: z.nativeEnum(AccidentHistorySelection),
  trunkFloor: z.nativeEnum(AccidentHistorySelection),
  rearPanel: z.nativeEnum(AccidentHistorySelection).optional(),
});

export type AccidentHistoryChecklist = z.input<typeof AccidentHistoryChecklistSchema>;
export const AccidentHistoryChecklistSchema = z.object({
  externalPanel: ExternalPanelPartSchema,
  mainFrame: MainFramePartSchema,
});
