import type { ValueOf } from '@daangn/car-utils/type';

import { z } from 'zod';

export const ReportItemStateSelection = {
  TuningIllegalStructure: 'TuningIllegalStructure',
  TuningIllegalEquipment: 'TuningIllegalEquipment',
  TuningStructure: 'TuningStructure',
  TuningEquipment: 'TuningEquipment',
  CheckRequired: 'CheckRequired',
  InspectionRequired: 'InspectionRequired',
  Noise: 'Noise',
  Lack: 'Lack',
  Defect: 'Defect',
  OilLeak: 'OilLeak',
  MinorOilLeak: 'MinorOilLeak',
  WaterLeak: 'WaterLeak',
  MinorWaterLeak: 'MinorWaterLeak',
  MinorImpact: 'MinorImpact',
  Impact: 'Impact',
  ReplacementRecommended: 'ReplacementRecommended',
  ReplacementRequired: 'ReplacementRequired',
  PaintRequired: 'PaintRequired',
  Moisture: 'Moisture',
  RepairRequired: 'RepairRequired',
  StoneChip: 'StoneChip',
  BendingRequired: 'BendingRequired',
  Scratch: 'Scratch',
  Issues: 'Issues',
  MaintenanceRequired: 'MaintenanceRequired',
  NoIssues: 'NoIssues',
  TireOneSidedWear: 'TireOneSidedWear',
  None: 'None',
  Used: 'Used',
  Corrosion: 'Corrosion',
  Damaged: 'Damaged',
  Hardened: 'Hardened',
  DoorDing: 'DoorDing',
} as const;
export type ReportItemStateSelection = ValueOf<typeof ReportItemStateSelection>;
export const ReportItemStateSelectionText: Record<ReportItemStateSelection, string> = {
  None: '없음',
  TuningIllegalEquipment: '불법 (장치)',
  TuningIllegalStructure: '불법 (구조)',
  TuningEquipment: '적법 (장치)',
  TuningStructure: '적법 (구조)',
  TireOneSidedWear: '편마모',
  NoIssues: '정상',
  Issues: '있음',
  Noise: '소음',
  Lack: '부족',
  Defect: '불량',
  MinorImpact: '미세충격',
  Impact: '충격',
  Moisture: '습기',
  StoneChip: '스톤칩',
  RepairRequired: '수리 필요',
  PaintRequired: '도색 필요',
  InspectionRequired: '점검 필요',
  BendingRequired: '판금 필요',
  MaintenanceRequired: '정비 필요',
  Scratch: '긁힘',
  MinorOilLeak: '미세 누유',
  OilLeak: '누유',
  CheckRequired: '확인 필요',
  MinorWaterLeak: '미세 누수',
  WaterLeak: '누수',
  ReplacementRecommended: '교체 권장',
  ReplacementRequired: '교체 필요',
  Used: '사용감',
  Corrosion: '부식',
  Damaged: '손상',
  Hardened: '경화',
  DoorDing: '문콕',
};

export const ReportImageSchema = z.object({
  id: z.string(),
  url: z.string(),
});
export type ReportItemImage = z.input<typeof ReportImageSchema>;

export type ReportItemState = z.input<typeof _ReportItemSchema>;
const _ReportItemSchema = z.object({
  selections: z.array(z.nativeEnum(ReportItemStateSelection)),
  comment: z.string().optional(),
  imgs: z.array(ReportImageSchema),
});
const clearEmptyState = (v: ReportItemState): ReportItemState => {
  if (v.selections.length === 0) {
    return {
      selections: ['NoIssues'] as ReportItemStateSelection[],
      imgs: [],
      comment: undefined,
    };
  }
  return v;
};

export const ReportItemStateSchema = _ReportItemSchema.transform(clearEmptyState);
export const ReportConsumableItemSchema = _ReportItemSchema.refine(
  (v) => {
    return v.selections.length > 0;
  },
  { message: '확인해 주세요.' }
);

export const ImageFirstReportItemSchema = _ReportItemSchema
  .refine(
    (v) => {
      const hasImage = !!v?.imgs?.length;
      const hasSelections = !!v.selections.length;
      if (!hasImage) {
        return true;
      }
      if (hasImage && hasSelections) {
        return true;
      }
      return false;
    },
    { message: '항목을 선택해 주세요.' }
  )
  .transform(clearEmptyState);
