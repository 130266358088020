import { RelayNetworkError } from '@daangn/car-utils/relay';
import { useCallback } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { GraphQLTaggedNode, OperationType, fetchQuery } from 'relay-runtime';

type CacheConfig = Parameters<typeof fetchQuery>[3];

const useFetchQuery = <TQuery extends OperationType>(query: GraphQLTaggedNode) => {
  const environment = useRelayEnvironment();
  const fetch: (
    variables: TQuery['variables'],
    config?: CacheConfig
  ) => Promise<TQuery['response']> = useCallback(
    (variables: TQuery['variables'], config?: CacheConfig) =>
      new Promise((resolve, reject) => {
        return fetchQuery<TQuery>(environment, query, variables, config).subscribe({
          next(result) {
            resolve(result);
          },
          error(e: RelayNetworkError) {
            reject(e);
          },
        });
      }),
    [environment, query]
  );

  return fetch;
};

export default useFetchQuery;
