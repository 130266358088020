import {
  openExternalBrowser as _openExternalBrowser,
  openInAppBrowser as _openInAppBrowser,
} from '@daangn/webview-link-router';
import { useActivityParams } from '@stackflow/react/future';
import { useMemo } from 'react';
import UrlPattern from 'url-pattern';

import { ActivityName, ActivityParams, activityDescription } from '@/stackflow/Activity';

import { convertToCamelCase, filterNil } from './misc';

export const useCamelCaseParams = <T extends { [key: string]: any }>(): T => {
  const params = useActivityParams();
  const converted = useMemo(() => {
    return convertToCamelCase(params) as T;
  }, [params]);
  return converted;
};

export const useCamelCaseActivityParams = <TActivityName extends ActivityName>() => {
  const params = useActivityParams() as ActivityParams<TActivityName>;
  const converted = useMemo(() => {
    return convertToCamelCase(params) as ActivityParams<TActivityName>;
  }, [params]);
  return converted;
};

export const openInAppBrowser = (url: string) => {
  _openInAppBrowser({ url, stage: process.env.WEBVIEW_STAGE });
};

export const openExternalBrowser = (url: string) => {
  _openExternalBrowser({ url, stage: process.env.WEBVIEW_STAGE });
};

const getCorrectPathnameTrailingLastSlash = (pathname: string) => {
  if (pathname === '/') {
    return pathname;
  }
  return pathname.endsWith('/') ? pathname.slice(0, pathname.length - 1) : pathname;
};

export const getActivityFromPathname = (pathname: string) => {
  const trailingPathname = getCorrectPathnameTrailingLastSlash(pathname);
  for (const [name, { url }] of Object.entries(activityDescription)) {
    if (url === '*') {
      continue;
    }
    const pattern = new UrlPattern(url);
    if (pattern.match(trailingPathname)) {
      return name;
    }
  }
  return undefined;
};

export const openKarrotMap = ({
  coordinates,
  name,
  address,
}: {
  address?: string;
  coordinates: { lat: number; lng: number };
  name?: string;
}) => {
  const params = new URLSearchParams(
    filterNil({
      lat: coordinates.lat.toString(),
      lng: coordinates.lng.toString(),
      name,
      address,
    })
  );
  const url = `https://map.kr.karrotmarket.com/marker?${params}`;

  openInAppBrowser(url);
};
