import { ActivityLoaderArgs } from '@stackflow/config';
import { graphql, loadQuery } from 'react-relay';

import type { loader_AuctionTradeProgressQuery } from '@/__generated__/loader_AuctionTradeProgressQuery.graphql';

import environment from '@/relay/environment';

export const AuctionTradeProgressQuery = graphql`
  query loader_AuctionTradeProgressQuery($auctionTradeId: ID!) {
    auctionTrade(id: $auctionTradeId) @required(action: THROW) {
      id
      status
      inspection {
        id
        ...InspectionMechanicCard_inspection
        ...InspectionPreparingCard_inspection
      }
      auction {
        biddersCount
      }
      auctionPayment {
        carPayment {
          carPaymentCancellationReason
        }
      }
      ...AuctionTradeInputProsCard_auctionTrade
      ...AuctionTradeCarDeliveryProgressCard_auctionTrade
      ...AuctionTradeInspectionReportCard_auctionTrade
      ...AuctionTradeBiddingProgressCard_auctionTrade
      ...AuctionTradeLargeTitle_auctionTrade
      ...AuctionTradeTimelineCard_auctionTrade
      ...AuctionTradeSelectWinnerBottomCTA_auctionTrade
      ...AuctionTradeInspectionNudgeBottomSheet_auctionTrade
      ...AuctionTradeCarRegistrationCard_auctionTrade
      ...AuctionTradeResultCard_auctionTrade
      ...AuctionTradeBuyerInfoCard_auctionTrade
      ...AuctionTradeCarDeliveryExplanationCard_auctionTrade
      ...AuctionTradeRefundInsuranceDocumentCard_auctionTrade
      ...AuctionTradeSellerCancelCard_auctionTrade
      ...AuctionTradeRefundInsuranceExplanationCard_auctionTrade
      ...AuctionTradeInspectionProgressCard_auctionTrade
      ...AuctionTradeRejectedReasonCard_auctionTrade
      ...AuctionTradeRestartInspectionCard_auctionTrade
      ...AuctionTradeRestartAuctionCard_auctionTrade
      ...AuctionTradePurchasingCanceledReasonCard_auctionTrade
      ...getAuctionTradeSellerStatus_auctionTrade
    }
  }
`;

export const auctionTradeProgressLoader = async ({
  params,
}: ActivityLoaderArgs<'auction_trade_progress'>) => {
  return {
    preloadedQuery: loadQuery<loader_AuctionTradeProgressQuery>(
      environment,
      AuctionTradeProgressQuery,
      {
        auctionTradeId: params.auctionTradeId,
      }
    ),
  };
};
