import type { ValueOf } from '@daangn/car-utils/type';

export const FuelType = {
  Gasoline: 'GASOLINE',
  Diesel: 'DIESEL',
  Lpg: 'LPG',
  Cng: 'CNG',
  Electronic: 'ELECTRONIC',
  FuelCellElectronic: 'FUEL_CELL_ELECTRONIC',
  HydrogenElectric: 'H2O_ELECTRONIC',
  Hybrid: 'HYBRID',
  GasolineElectronic: 'GASOLINE_ELECTRONIC',
  DieselElectronic: 'DIESEL_ELECTRONIC',
  LpgGasoline: 'LPG_GASOLINE',
  LpgElectronic: 'LPG_ELECTRONIC',
  Unknown: 'UNKNOWN',
} as const;

export const visibleFuelTypes: FuelType[] = [
  FuelType.Gasoline,
  FuelType.Diesel,
  FuelType.Lpg,
  FuelType.Cng,
  FuelType.Electronic,
  FuelType.HydrogenElectric,
  FuelType.FuelCellElectronic,
  FuelType.Hybrid,
];

export const OneToNFuelTypes: Partial<Record<FuelType, FuelType[]>> = {
  [FuelType.Hybrid]: [
    FuelType.Hybrid,
    FuelType.GasolineElectronic,
    FuelType.DieselElectronic,
    FuelType.LpgGasoline,
    FuelType.LpgElectronic,
  ],
};

export const NoDisplacementFuelTypes = [
  FuelType.Electronic,
  FuelType.FuelCellElectronic,
  FuelType.HydrogenElectric,
] as FuelType[];

export const FuelTypeText = {
  [FuelType.Gasoline]: '가솔린(휘발유)',
  [FuelType.Diesel]: '디젤(경유)',
  [FuelType.Lpg]: 'LPG',
  [FuelType.Cng]: 'CNG(천연가스)',
  [FuelType.Electronic]: '전기',
  [FuelType.FuelCellElectronic]: '태양광',
  [FuelType.HydrogenElectric]: '수소전기',
  [FuelType.Hybrid]: '하이브리드',
  [FuelType.GasolineElectronic]: '하이브리드(가솔린+전기)',
  [FuelType.DieselElectronic]: '하이브리드(디젤+전기)',
  [FuelType.LpgGasoline]: '하이브리드(LPG+가솔린)',
  [FuelType.LpgElectronic]: '하이브리드(LPG+전기)',
  [FuelType.Unknown]: '알 수 없음',
};
export type FuelType = ValueOf<typeof FuelType>;
