import { z } from 'zod';

import { ReportConsumableItemSchema, ReportItemStateSchema } from './ReportItemState';
export type EngineOperationPart = z.input<typeof EngineOperationPartSchema>;
export const EngineOperationPartSchema = z.object({
  ignition: ReportItemStateSchema,
  idle: ReportItemStateSchema,
  acceleration: ReportItemStateSchema,
});
export type EngineOilPart = z.input<typeof EngineOilPartSchema>;
export const EngineOilPartSchema = z.object({
  flow: ReportConsumableItemSchema,
  condition: ReportConsumableItemSchema,
});
export type EngineOilLeakPart = z.input<typeof EngineOilLeakPartSchema>;
export const EngineOilLeakPartSchema = z.object({
  rockerArmCover: ReportItemStateSchema,
  cylinderHead: ReportItemStateSchema,
  engineBlock: ReportItemStateSchema,
  oilPan: ReportItemStateSchema,
  frontCover: ReportItemStateSchema.optional(),
});
export type CoolantLeakPart = z.input<typeof CoolantLeakPartSchema>;
export const CoolantLeakPartSchema = z.object({
  cylinderHead: ReportItemStateSchema,
  waterPump: ReportItemStateSchema,
  radiator: ReportItemStateSchema,
});
export type CoolantPart = z.input<typeof CoolantPartSchema>;
export const CoolantPartSchema = z.object({
  level: ReportConsumableItemSchema,
  condition: ReportConsumableItemSchema,
});
export type HighPressurePumpPart = z.input<typeof HighPressurePumpPartSchema>;
export const HighPressurePumpPartSchema = z.object({
  highPressurePump: ReportItemStateSchema,
});
export type EngineChecklist = z.input<typeof EngineChecklistSchema>;
export const EngineChecklistSchema = z.object({
  operation: EngineOperationPartSchema,
  engineOil: EngineOilPartSchema,
  oilLeak: EngineOilLeakPartSchema,
  coolant: CoolantPartSchema,
  coolantLeak: CoolantLeakPartSchema,
  highPressurePump: HighPressurePumpPartSchema,
});
