import type { GetFieldTypeStrictly, Mutable, Prettify } from './type';
import { produce } from 'immer';
import { set, get } from 'es-toolkit/compat';

type Func = (...args: any) => any;

export const compose = (...funcs: Func[]) => {
  if (funcs.length === 0) {
    return <T>(arg: T) => arg;
  }

  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce(
    (a, b) =>
      (...args: any) =>
        a(b(...args))
  );
};

type Fn<A, B> = (arg: A) => B;
type Nullish<T> = T | null | undefined;
export function pipeWithNullifyValue<A, B>(f1: Fn<A, B>): Fn<Nullish<A>, B | undefined>;
export function pipeWithNullifyValue<A, B, C>(
  f1: Fn<A, B>,
  f2: Fn<NonNullable<B>, C>
): Fn<Nullish<A>, C | undefined>;
export function pipeWithNullifyValue<A, B, C, D>(
  f1: Fn<A, B>,
  f2: Fn<NonNullable<B>, C>,
  f3: Fn<NonNullable<C>, D>
): Fn<Nullish<A>, D | undefined>;
export function pipeWithNullifyValue<A, B, C, D, E>(
  f1: Fn<A, B>,
  f2: Fn<NonNullable<B>, C>,
  f3: Fn<NonNullable<C>, D>,
  f4: Fn<NonNullable<D>, E>
): Fn<Nullish<A>, E | undefined>;
export function pipeWithNullifyValue<A, B, C, D, E, F>(
  f1: Fn<A, B>,
  f2: Fn<NonNullable<B>, C>,
  f3: Fn<NonNullable<C>, D>,
  f4: Fn<NonNullable<D>, E>,
  f5: Fn<NonNullable<E>, F>
): Fn<Nullish<A>, F | undefined>;
export function pipeWithNullifyValue<A, B, C, D, E, F, G>(
  f1: Fn<A, B>,
  f2: Fn<NonNullable<B>, C>,
  f3: Fn<NonNullable<C>, D>,
  f4: Fn<NonNullable<D>, E>,
  f5: Fn<NonNullable<E>, F>,
  f6: Fn<NonNullable<F>, G>
): Fn<Nullish<A>, G | undefined>;
export function pipeWithNullifyValue<A, B, C, D, E, F, G, H>(
  f1: Fn<A, B>,
  f2: Fn<NonNullable<B>, C>,
  f3: Fn<NonNullable<C>, D>,
  f4: Fn<NonNullable<D>, E>,
  f5: Fn<NonNullable<E>, F>,
  f6: Fn<NonNullable<F>, G>,
  f7: Fn<NonNullable<G>, H>
): Fn<Nullish<A>, H | undefined>;
export function pipeWithNullifyValue<A, B, C, D, E, F, G, H, I>(
  f1: Fn<A, B>,
  f2: Fn<NonNullable<B>, C>,
  f3: Fn<NonNullable<C>, D>,
  f4: Fn<NonNullable<D>, E>,
  f5: Fn<NonNullable<E>, F>,
  f6: Fn<NonNullable<F>, G>,
  f7: Fn<NonNullable<G>, H>,
  f8: Fn<NonNullable<H>, I>
): Fn<Nullish<A>, I | undefined>;
export function pipeWithNullifyValue<A, B, C, D, E, F, G, H, I, J>(
  f1: Fn<A, B>,
  f2: Fn<NonNullable<B>, C>,
  f3: Fn<NonNullable<C>, D>,
  f4: Fn<NonNullable<D>, E>,
  f5: Fn<NonNullable<E>, F>,
  f6: Fn<NonNullable<F>, G>,
  f7: Fn<NonNullable<G>, H>,
  f8: Fn<NonNullable<H>, I>,
  f9: Fn<NonNullable<I>, J>
): Fn<Nullish<A>, J | undefined>;
// eslint-disable-next-line @typescript-eslint/ban-types
export function pipeWithNullifyValue(...funcs: Function[]): Function {
  return (arg: any): any => {
    if (arg == null) {
      return undefined;
    }
    return funcs.reduce((result, func) => {
      if (result == null) {
        return undefined;
      }
      return func(result);
    }, arg);
  };
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const setIn = <TObject extends object, TPath extends string = string>(
  object: TObject,
  path: TPath,
  nextValue: GetFieldTypeStrictly<TObject, TPath>
): TObject => {
  return produce(object, (draft) => {
    set(draft, path, nextValue);
  });
};

export const getIn = <TObject, TPath extends string = string>(
  object: TObject,
  path: TPath
): GetFieldTypeStrictly<TObject, TPath> => {
  return get(object, path as string);
};

export type RecursivelyReplaceNullWithUndefined<T> = T extends null
  ? undefined
  : T extends Date
    ? T
    : {
        [K in keyof T]: T[K] extends (infer U)[]
          ? RecursivelyReplaceNullWithUndefined<U>[]
          : RecursivelyReplaceNullWithUndefined<T[K]>;
      };

export function mutable<T>(v: T) {
  return v as Prettify<Mutable<T>>;
}

export function convertNullToUndefinedRecursively<T>(
  obj: T
): RecursivelyReplaceNullWithUndefined<Mutable<T>> {
  const recursive = (obj: any) => {
    if (obj == null) {
      return undefined as any;
    }
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        obj[i] = recursive(obj[i]) as any;
      }
    }
    // object check based on: https://stackoverflow.com/a/51458052/6489012
    if (obj.constructor.name === 'Object') {
      for (const key in obj) {
        obj[key] = recursive(obj[key]) as any;
      }
    }

    return obj;
  };
  const newObj = produce(obj, (draft) => recursive(draft));
  return newObj as any;
}
