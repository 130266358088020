import { z } from 'zod';

import { ReportItemStateSchema } from './ReportItemState';

export type DriveShaftPart = z.input<typeof DriveShaftPartSchema>;
export const DriveShaftPartSchema = z.object({
  rubberBootDamage: ReportItemStateSchema,
  greaseLeak: ReportItemStateSchema,
});
export type DifferentialPart = z.input<typeof DifferentialPartSchema>;
export const DifferentialPartSchema = z.object({
  caseLeak: ReportItemStateSchema,
  oilSealLeak: ReportItemStateSchema,
});
export type TransferCasePart = z.input<typeof TransferCasePartSchema>;
export const TransferCasePartSchema = z.object({
  caseLeak: ReportItemStateSchema,
  oilSealLeak: ReportItemStateSchema,
});
export type ClutchAssemblyPart = z.input<typeof ClutchAssemblyPartSchema>;
export const ClutchAssemblyPartSchema = z.object({
  clutchAssembly: ReportItemStateSchema,
});
export type PropellerShaftPart = z.input<typeof PropellerShaftPartSchema>;
export const PropellerShaftPartSchema = z.object({
  shaftLooseness: ReportItemStateSchema,
  discDamage: ReportItemStateSchema,
  bearingDamage: ReportItemStateSchema,
});
export type PowertrainChecklist = z.input<typeof PowertrainChecklistSchema>;
export const PowertrainChecklistSchema = z.object({
  driveShaft: DriveShaftPartSchema,
  differential: DifferentialPartSchema,
  transferCase: TransferCasePartSchema,
  propellerShaft: PropellerShaftPartSchema,
  clutchAssembly: ClutchAssemblyPartSchema,
});
