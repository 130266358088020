import type { ValueOf } from '@daangn/car-utils/type';

export const GearType = {
  Auto: 'AUTO',
  Manual: 'MANUAL',
  Cvt: 'CVT',
  SemiAuto: 'SEMI_AUTO',
  Unknown: 'UNKNOWN',
} as const;

export const visibleGearTypes: GearType[] = [GearType.Auto, GearType.Manual];

export const OneToNGearTypes: Partial<Record<GearType, GearType[]>> = {
  [GearType.Auto]: [GearType.Auto, GearType.Cvt, GearType.SemiAuto],
};

export const GearTypeText = {
  [GearType.Auto]: '자동 (A/T)',
  [GearType.Manual]: '수동 (M/T)',
  [GearType.Cvt]: '자동 (A/T)',
  [GearType.SemiAuto]: '자동 (A/T)',
  [GearType.Unknown]: '알 수 없음',
};

export type GearType = ValueOf<typeof GearType>;
