import type { z } from 'zod';

import { defineConfig } from '@stackflow/config';
import { objectEntries } from '@toss/utils';

import { activityDescription } from '@/stackflow/Activity';
import { activityLoader } from '@/stackflow/activityLoader';

export const stackflowConfig = defineConfig({
  activities: objectEntries(activityDescription).map(([name, { url, schema }]) => ({
    name,
    path: url,
    decode: schema.parse,
    loader: activityLoader[name],
  })),
  transitionDuration: 350,
});

type ActivityDescription = typeof activityDescription;
type Register2 = {
  [K in keyof ActivityDescription]: z.output<ActivityDescription[K]['schema']>;
};
declare module '@stackflow/config' {
  interface Register extends Register2 {}
}
