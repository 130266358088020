import { createStorageState } from '@daangn/car-utils/storage';
import { v4 as uuidv4 } from 'uuid';

import { FilterState } from '@/components/Filter/FilterContextProvider';
import CONFIG from '@/constants/config';
import { FormArticle } from '@/store/article';
import { WriteStep } from '@/store/write';

export const StorageKey = {
  NotificationMaxReached: 'NotificationMaxReached',
  TempArticle: 'TempArticle_v2',
  TempWriteStep: 'TempWriteStep',
  Filter: 'Filter2',
  GAClientID: 'ga:clientId1',
  HyundaiCapitalArticleId: 'hyundai_capital_article_id',
};

export const getStorageKey = (key: string, isCommon?: boolean) =>
  `${isCommon ? 'CAR_COMMON' : CONFIG.SERVICE_KEY}#${key}`;

const { get: getFilter, set: setFilter } = createStorageState<FilterState | null>(
  getStorageKey(StorageKey.Filter),
  {
    storage: 'localStorage',
    defaultValue: null,
  }
);

const { get: getGaClientId } = createStorageState<string>(getStorageKey(StorageKey.GAClientID), {
  storage: 'localStorage',
  defaultValue: uuidv4(),
});

const { get: _getTempArticle, set: _setTempArticle } = createStorageState<FormArticle | null>(
  getStorageKey(StorageKey.TempArticle),
  {
    storage: 'localStorage',
    defaultValue: null,
  }
);

const { get: getTempWriteStep, set: setTempWriteStep } = createStorageState<WriteStep>(
  getStorageKey(StorageKey.TempWriteStep),
  {
    storage: 'localStorage',
    defaultValue: WriteStep.CarInfo,
  }
);

const { get: getNotificationMaxReached, set: setNotificationMaxReached } =
  createStorageState<boolean>(getStorageKey(StorageKey.NotificationMaxReached), {
    storage: 'localStorage',
    defaultValue: false,
  });

class CarStorage {
  getFilter = getFilter;
  getGaClientId = getGaClientId;
  getNotificationMaxReached = getNotificationMaxReached;
  getTempWriteStep = getTempWriteStep;
  setFilter = setFilter;
  setNotificationMaxReached = setNotificationMaxReached;
  setTempWriteStep = setTempWriteStep;
  getTempArticle(): FormArticle | null {
    const parsed = _getTempArticle();
    if (!parsed?.ownerName || !parsed?.carNo) {
      return null;
    }
    return parsed;
  }
  setTempArticle(article: any) {
    _setTempArticle(article);
  }
}

const { get: getReferrer, set: setReferrer } = createStorageState<null | string>(
  getStorageKey(StorageKey.TempWriteStep),
  {
    storage: 'sessionStorage',
    defaultValue: null,
  }
);

const { get: getHyundaiCapitalArticleId, set: setHyundaiCapitalArticleId } = createStorageState<
  null | string
>(getStorageKey(StorageKey.HyundaiCapitalArticleId), {
  storage: 'sessionStorage',
  defaultValue: null,
});
class SessionStorage {
  getHyundaiCapitalArticleId = getHyundaiCapitalArticleId;
  getReferrer = getReferrer;
  setHyundaiCapitalArticleId = setHyundaiCapitalArticleId;
  setReferrer = setReferrer;
}

export const carStorage = new CarStorage();
export const sessionStorage = new SessionStorage();
