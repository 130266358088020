const getPathCompatibleUrl = (url: string) => {
  const isPath = url.startsWith('/');
  return new URL(isPath ? `https://daangn.com${url}` : url);
};

export const getUrlWithQuery = (url: string, queries?: Record<string, unknown>) => {
  if (!queries) {
    return url;
  }
  const isPath = url.startsWith('/');
  const link = getPathCompatibleUrl(url);

  Object.entries(queries)
    .filter(([, value]) => value != null)
    .forEach(([key, value]) => {
      link.searchParams.set(key, String(value));
    });

  return isPath ? link.pathname + link.search : link.toString();
};

export const getQueryFromUrl = (url: string) => {
  const link = getPathCompatibleUrl(url);
  const result: Record<string, string> = {};

  for (const [key, value] of link.searchParams.entries()) {
    result[key] = value;
  }
  return result;
};
