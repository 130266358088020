import { z } from 'zod';

import { CarImageSchema } from '../Image/CarImage';
import { ImageType } from './ImageType';

export type ArticleImage = z.input<typeof ArticleImageSchema>;

const ArticleImageSchema = z
  .object({
    type: z.nativeEnum(ImageType),
    images: z.array(CarImageSchema),
  })
  .refine(
    ({ type, images }) => (type === 'INTERIOR' || type === 'EXTERIOR' ? images.length >= 3 : true),
    {
      message: '사진을 3장 이상 업로드해 주세요.',
      path: ['images'],
    }
  );
