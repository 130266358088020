import type { ValueOf } from '@daangn/car-utils/type';

export const AccidentType = {
  Accident: 'ACCIDENT',
  NoAccident: 'NO_ACCIDENT',
  AbsoluteNoAccident: 'ABSOLUTE_NO_ACCIDENT',
} as const;

export type AccidentType = ValueOf<typeof AccidentType>;

export const AccidentTypeText = {
  [AccidentType.Accident]: '사고',
  [AccidentType.NoAccident]: '무사고',
  [AccidentType.AbsoluteNoAccident]: '완전무사고',
};

export const AccidentTypeTextForDealer = {
  [AccidentType.Accident]: '유사고',
  [AccidentType.NoAccident]: '단순교환 무사고',
  [AccidentType.AbsoluteNoAccident]: '완전 무사고',
};
