import type { ValueOf } from '@daangn/car-utils/type';

export const SaleType = {
  General: 'GENERAL',
  Lease: 'LEASE',
  Rental: 'RENTAL',
} as const;

export const SaleTypeText = {
  [SaleType.General]: '일반',
  [SaleType.Lease]: '리스 승계',
  [SaleType.Rental]: '렌트',
};
export type SaleType = ValueOf<typeof SaleType>;
