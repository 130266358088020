import type { ValueOf } from '@daangn/car-utils/type';

export const MetadataCarType = {
  Mini: 'MINI',
  Subcompact: 'SUBCOMPACT',
  Compact: 'COMPACT',
  MidSize: 'MID_SIZE',
  LargeSize: 'LARGE_SIZE',
  Truck: 'TRUCK',
  Sports: 'SPORTS',
  MiniVan: 'MINI_VAN',
  Van: 'VAN',
  Suv: 'SUV',
  Rv: 'RV',
  Bus: 'BUS',
  Etc: 'ETC',
} as const;

export const MetadataCarTypeText = {
  [MetadataCarType.Mini]: '경차',
  [MetadataCarType.Subcompact]: '소형차',
  [MetadataCarType.Compact]: '준중형차',
  [MetadataCarType.MidSize]: '중형차',
  [MetadataCarType.LargeSize]: '대형차',
  [MetadataCarType.Truck]: '화물차',
  [MetadataCarType.Sports]: '스포츠카',
  [MetadataCarType.MiniVan]: '경승합차',
  [MetadataCarType.Van]: '승합차',
  [MetadataCarType.Suv]: 'SUV',
  [MetadataCarType.Rv]: 'RV',
  [MetadataCarType.Bus]: '버스',
  [MetadataCarType.Etc]: '기타',
};
export type MetadataCarType = ValueOf<typeof MetadataCarType>;
