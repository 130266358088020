import type { ValueOf } from '@daangn/car-utils/type';

export const AuctionTradeProsCarKey = {
  ONLY_ONE: 'ONLY_ONE',
  TWO_OR_MORE: 'TWO_OR_MORE',
} as const;

export type AuctionTradeProsCarKey = ValueOf<typeof AuctionTradeProsCarKey>;

export const AuctionTradeProsCarKeyText: Record<AuctionTradeProsCarKey, string> = {
  ONLY_ONE: '1개',
  TWO_OR_MORE: '2개 이상',
};
