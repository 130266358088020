/* eslint-disable perfectionist/sort-imports */
import BNP파리바 from '../assets/images/banks/banking=bank_BNP파리바.png';
import BOA from '../assets/images/banks/banking=bank_BOA.png';
import HSBC from '../assets/images/banks/banking=bank_HSBC.png';
import JP모간 from '../assets/images/banks/banking=bank_JP모간.png';
import SBI저축은행 from '../assets/images/banks/banking=bank_sbi저축은행.png';
import SC제일 from '../assets/images/banks/banking=bank_sc제일.png';
import 경남 from '../assets/images/banks/banking=bank_경남.png';
import 광주 from '../assets/images/banks/banking=bank_광주.png';
import 국민 from '../assets/images/banks/banking=bank_국민.png';
import 기업 from '../assets/images/banks/banking=bank_기업.png';
import 농협 from '../assets/images/banks/banking=bank_농협.png';
import 대구 from '../assets/images/banks/banking=bank_대구.png';
import 도이치 from '../assets/images/banks/banking=bank_도이치.png';
import 부산 from '../assets/images/banks/banking=bank_부산.png';
import 산립조합 from '../assets/images/banks/banking=bank_산립조합.png';
import 산업 from '../assets/images/banks/banking=bank_산업.png';
import 새마을 from '../assets/images/banks/banking=bank_새마을.png';
import 수협 from '../assets/images/banks/banking=bank_수협.png';
import 시티 from '../assets/images/banks/banking=bank_시티.png';
import 신한 from '../assets/images/banks/banking=bank_신한.png';
import 신협 from '../assets/images/banks/banking=bank_신협.png';
import 우리 from '../assets/images/banks/banking=bank_우리.png';
import 우체국 from '../assets/images/banks/banking=bank_우체국.png';
import 저축은행 from '../assets/images/banks/banking=bank_저축은행.png';
import 전북 from '../assets/images/banks/banking=bank_전북.png';
import 제주 from '../assets/images/banks/banking=bank_제주.png';
import 중국건설 from '../assets/images/banks/banking=bank_중국건설.png';
import 중국공상 from '../assets/images/banks/banking=bank_중국공상.png';
import 중국은행 from '../assets/images/banks/banking=bank_중국은행.png';
import 카카오뱅크 from '../assets/images/banks/banking=bank_카카오뱅크.png';
import 케이뱅크 from '../assets/images/banks/banking=bank_케이뱅크.png';
import 토스뱅크 from '../assets/images/banks/banking=bank_토스뱅크.png';
import 하나 from '../assets/images/banks/banking=bank_하나.png';
import BNK투자 from '../assets/images/banks/banking=stock_bnk투자.png';
import DB금융투자 from '../assets/images/banks/banking=stock_DB금융투자.png';
import IBK투자 from '../assets/images/banks/banking=stock_IBK투자.png';
import KB증권 from '../assets/images/banks/banking=stock_KB증권.png';
import NH투자 from '../assets/images/banks/banking=stock_NH투자.png';
import SK from '../assets/images/banks/banking=stock_SK.png';
import 교보 from '../assets/images/banks/banking=stock_교보.png';
import 다올투자증권 from '../assets/images/banks/banking=stock_다올투자증권.png';
import 대신 from '../assets/images/banks/banking=stock_대신.png';
import 메리츠증권 from '../assets/images/banks/banking=stock_메리츠증권.png';
import 미래에셋 from '../assets/images/banks/banking=stock_미래에셋.png';
import 부국 from '../assets/images/banks/banking=stock_부국.png';
import 삼성증권 from '../assets/images/banks/banking=stock_삼성증권.png';
import 신영 from '../assets/images/banks/banking=stock_신영.png';
import 신한금융투자 from '../assets/images/banks/banking=stock_신한금융투자.png';
import 유안타증권 from '../assets/images/banks/banking=stock_유안타증권.png';
import 유진투자 from '../assets/images/banks/banking=stock_유진투자.png';
import 이베스트 from '../assets/images/banks/banking=stock_이베스트.png';
import 카카오페이증권 from '../assets/images/banks/banking=stock_카카오페이증권.png';
import 케이프투자 from '../assets/images/banks/banking=stock_케이프투자.png';
import 키움 from '../assets/images/banks/banking=stock_키움.png';
import 토스증권 from '../assets/images/banks/banking=stock_토스증권.png';
import 하나증권 from '../assets/images/banks/banking=stock_하나증권.png';
import 하이투자 from '../assets/images/banks/banking=stock_하이투자.png';
import 한국투자 from '../assets/images/banks/banking=stock_한국투자.png';
import 한국포스증권 from '../assets/images/banks/banking=stock_한국포스증권.png';
import 한화투자 from '../assets/images/banks/banking=stock_한화투자.png';
import 현대차증권 from '../assets/images/banks/banking=stock_현대차증권.png';
import 기본 from '../assets/images/banks/banking=당근은행.png';

export type BankItem = {
  fullName: string;
  shortName: string;
  thumbnail: string;
  type: 'bank' | 'stock';
  value: string;
  visible: boolean;
};

export const bankItems: BankItem[] = [
  {
    value: '089',
    shortName: '케이뱅크',
    fullName: '케이뱅크',
    thumbnail: 케이뱅크,
    type: 'bank',
    visible: true,
  },
  {
    value: '088',
    shortName: '신한',
    fullName: '신한은행',
    type: 'bank',
    visible: true,
    thumbnail: 신한,
  },
  {
    value: '090',
    shortName: '카카오뱅크',
    fullName: '카카오뱅크',
    thumbnail: 카카오뱅크,
    type: 'bank',
    visible: true,
  },
  {
    value: '011',
    shortName: '농협',
    fullName: '농협은행',
    type: 'bank',
    visible: true,
    thumbnail: 농협,
  },
  {
    value: '081',
    shortName: '하나',
    fullName: '하나은행',
    type: 'bank',
    visible: true,
    thumbnail: 하나,
  },
  {
    value: '004',
    shortName: '국민',
    fullName: '국민은행',
    type: 'bank',
    visible: true,
    thumbnail: 국민,
  },
  {
    value: '020',
    shortName: '우리',
    fullName: '우리은행',
    type: 'bank',
    visible: true,
    thumbnail: 우리,
  },
  {
    value: '003',
    shortName: '기업',
    fullName: '기업은행',
    type: 'bank',
    visible: true,
    thumbnail: 기업,
  },
  {
    value: '045',
    shortName: '새마을금고',
    fullName: '새마을금고중앙회',
    type: 'bank',
    visible: true,
    thumbnail: 새마을,
  },

  {
    value: '031',
    shortName: '대구',
    fullName: '대구은행',
    type: 'bank',
    thumbnail: 대구,
    visible: true,
  },
  {
    value: '032',
    shortName: '부산',
    fullName: '부산은행',
    type: 'bank',
    thumbnail: 부산,
    visible: true,
  },
  {
    value: '092',
    shortName: '토스뱅크',
    fullName: '토스뱅크',
    type: 'bank',
    thumbnail: 토스뱅크,
    visible: true,
  },
  {
    value: '023',
    shortName: 'SC제일',
    fullName: 'SC제일은행',
    type: 'bank',
    visible: true,
    thumbnail: SC제일,
  },
  {
    value: '071',
    shortName: '우체국',
    fullName: '우체국',
    thumbnail: 우체국,
    type: 'bank',
    visible: true,
  },
  {
    value: '048',
    shortName: '신협',
    fullName: '신협',
    type: 'bank',
    visible: true,
    thumbnail: 신협,
  },
  {
    value: '050',
    shortName: '저축',
    fullName: '저축은행',
    type: 'bank',
    visible: true,
    thumbnail: 저축은행,
  },
  {
    value: '034',
    shortName: '광주',
    fullName: '광주은행',
    type: 'bank',
    visible: true,
    thumbnail: 광주,
  },
  {
    value: '039',
    shortName: '경남',
    fullName: '경남은행',
    type: 'bank',
    visible: true,
    thumbnail: 경남,
  },
  {
    value: '027',
    shortName: '시티',
    fullName: '한국씨티은행',
    type: 'bank',
    visible: true,
    thumbnail: 시티,
  },
  {
    value: '002',
    shortName: '산업',
    fullName: '산업은행',
    type: 'bank',
    visible: true,
    thumbnail: 산업,
  },
  {
    value: '037',
    shortName: '전북',
    fullName: '전북은행',
    type: 'bank',
    visible: true,
    thumbnail: 전북,
  },
  {
    value: '007',
    shortName: '수협',
    fullName: '수협은행',
    type: 'bank',
    visible: true,
    thumbnail: 수협,
  },
  {
    value: '035',
    shortName: '제주',
    fullName: '제주은행',
    type: 'bank',
    visible: true,
    thumbnail: 제주,
  },
  {
    value: '064',
    shortName: '산림조합',
    fullName: '산림조합중앙회',
    type: 'bank',
    visible: true,
    thumbnail: 산립조합,
  },
  {
    value: '238',
    shortName: '미래에셋',
    fullName: '미래에셋증권',
    type: 'stock',
    visible: true,
    thumbnail: 미래에셋,
  },
  {
    value: '243',
    shortName: '한국투자',
    fullName: '한국투자증권',
    type: 'stock',
    visible: true,
    thumbnail: 한국투자,
  },
  {
    value: '218',
    shortName: 'KB증권',
    fullName: 'KB증권',
    type: 'stock',
    visible: true,
    thumbnail: KB증권,
  },
  {
    value: '240',
    shortName: '삼성증권',
    fullName: '삼성증권',
    type: 'stock',
    visible: true,
    thumbnail: 삼성증권,
  },
  {
    value: '264',
    shortName: '키움',
    fullName: '키움증권',
    type: 'stock',
    visible: true,
    thumbnail: 키움,
  },
  {
    value: '289',
    shortName: 'NH투자',
    fullName: 'NH투자증권',
    type: 'stock',
    visible: true,
    thumbnail: NH투자,
  },
  {
    value: '278',
    shortName: '신한투자',
    fullName: '신한금융투자',
    thumbnail: 신한금융투자,
    type: 'stock',
    visible: true,
  },
  {
    value: '270',
    shortName: '하나증권',
    fullName: '하나금융투자',
    thumbnail: 하나증권,
    type: 'stock',
    visible: true,
  },
  {
    value: '267',
    shortName: '대신',
    fullName: '대신증권',
    type: 'stock',
    visible: true,
    thumbnail: 대신,
  },
  {
    value: '266',
    shortName: 'SK',
    fullName: 'SK증권',
    type: 'stock',
    visible: true,
    thumbnail: SK,
  },
  {
    value: '280',
    shortName: '유진투자',
    fullName: '유진투자증권',
    type: 'stock',
    visible: true,
    thumbnail: 유진투자,
  },
  {
    value: '279',
    shortName: 'DB금융투자',
    fullName: 'DB금융투자',
    type: 'stock',
    visible: true,
    thumbnail: DB금융투자,
  },
  {
    value: '269',
    shortName: '한화투자',
    fullName: '한화투자증권',
    type: 'stock',
    visible: true,
    thumbnail: 한화투자,
  },
  {
    value: '263',
    shortName: '현대차증권',
    fullName: '현대차증권',
    type: 'stock',
    visible: true,
    thumbnail: 현대차증권,
  },
  {
    value: '262',
    shortName: '하이투자',
    fullName: '하이투자증권',
    type: 'stock',
    visible: true,
    thumbnail: 하이투자,
  },
  {
    value: '227',
    shortName: '다올투자증권',
    fullName: '다올투자증권',
    type: 'stock',
    visible: true,
    thumbnail: 다올투자증권,
  },
  {
    value: '261',
    shortName: '교보',
    fullName: '교보',
    type: 'stock',
    thumbnail: 교보,
    visible: true,
  },
  {
    value: '287',
    shortName: '메리츠증권',
    fullName: '메리츠종합금융증권',
    type: 'stock',
    thumbnail: 메리츠증권,
    visible: true,
  },
  {
    value: '271',
    shortName: '토스증권',
    fullName: '토스증권',
    type: 'stock',
    thumbnail: 토스증권,
    visible: true,
  },
  {
    value: '209',
    shortName: '유안타증권',
    fullName: '유안타증권',
    type: 'stock',
    thumbnail: 유안타증권,
    visible: true,
  },
  {
    value: '001',
    shortName: '한국',
    fullName: '한국은행',
    type: 'bank',
    thumbnail: 기본,
    visible: false,
  },

  {
    value: '005',
    shortName: '외환',
    fullName: '외환은행',
    type: 'bank',
    thumbnail: 기본,
    visible: false,
  },

  {
    value: '008',
    shortName: '수출입',
    fullName: '수출입은행',
    type: 'bank',
    thumbnail: 기본,
    visible: false,
  },

  {
    value: '012',
    shortName: '지역농축협',
    fullName: '지역농축협',
    type: 'bank',
    thumbnail: 산업,
    visible: false,
  },

  {
    value: '051',
    shortName: '기타외국계',
    fullName: '기타외국계',
    type: 'bank',
    thumbnail: 산업,
    visible: false,
  },
  {
    value: '052',
    shortName: '모건스탠리',
    fullName: '모건스탠리',
    type: 'bank',
    thumbnail: 산업,
    visible: false,
  },
  {
    value: '054',
    shortName: 'HSBC',
    fullName: 'HSBC',
    type: 'bank',
    thumbnail: HSBC,
    visible: false,
  },
  {
    value: '055',
    shortName: '도이치',
    fullName: '도이치',
    type: 'bank',
    thumbnail: 도이치,
    visible: false,
  },
  {
    value: '057',
    shortName: '제이피모간체이스',
    fullName: '제이피모간체이스',
    type: 'bank',
    thumbnail: JP모간,
    visible: false,
  },
  {
    value: '058',
    shortName: '미즈호',
    fullName: '미즈호',
    type: 'bank',
    thumbnail: 기본,
    visible: false,
  },
  {
    value: '059',
    shortName: '엠유에프지',
    fullName: '엠유에프지',
    type: 'bank',
    thumbnail: 기본,
    visible: false,
  },
  {
    value: '060',
    shortName: 'BOA',
    fullName: 'BOA',
    type: 'bank',
    thumbnail: BOA,
    visible: false,
  },
  {
    value: '061',
    shortName: '비엔피파리',
    fullName: '비엔피파리',
    type: 'bank',
    thumbnail: BNP파리바,
    visible: false,
  },
  {
    value: '062',
    shortName: '중국공상',
    fullName: '중국공상',
    type: 'bank',
    thumbnail: 중국공상,
    visible: false,
  },
  {
    value: '063',
    shortName: '중국',
    fullName: '중국',
    type: 'bank',
    thumbnail: 중국은행,
    visible: false,
  },
  {
    value: '065',
    shortName: '대화',
    fullName: '대화',
    type: 'bank',
    thumbnail: 기본,
    visible: false,
  },
  {
    value: '066',
    shortName: '교통',
    fullName: '교통',
    type: 'bank',
    thumbnail: 기본,
    visible: false,
  },
  {
    value: '067',
    shortName: '중국건설',
    fullName: '중국건설',
    type: 'bank',
    thumbnail: 중국건설,
    visible: false,
  },

  {
    value: '076',
    type: 'bank',
    shortName: '신용보증기금',
    fullName: '신용보증기금',
    thumbnail: 기본,
    visible: false,
  },
  {
    value: '077',
    shortName: '기술보증기금',
    fullName: '기술보증기금',
    thumbnail: 기본,
    visible: false,
    type: 'bank',
  },

  {
    value: '091',
    shortName: '국세청',
    fullName: '국세청',
    type: 'bank',
    thumbnail: 기본,
    visible: false,
  },
  {
    value: '093',
    shortName: '한국주택금융공사',
    fullName: '한국주택금융공사',
    thumbnail: 기본,
    visible: false,
    type: 'stock',
  },
  {
    value: '094',
    shortName: '서울보증보험',
    fullName: '서울보증보험',
    thumbnail: 기본,
    visible: false,
    type: 'stock',
  },
  {
    value: '095',
    shortName: '경찰청',
    fullName: '경찰청',
    thumbnail: 기본,
    visible: false,
    type: 'stock',
  },
  {
    value: '096',
    shortName: '한국전자금융(주)',
    fullName: '한국전자금융(주)',
    thumbnail: 기본,
    visible: false,
    type: 'stock',
  },
  {
    value: '099',
    shortName: '금융결제원',
    fullName: '금융결제원',
    thumbnail: 기본,
    visible: false,
    type: 'stock',
  },
  {
    value: '101',
    shortName: '한국신용정보원',
    fullName: '한국신용정보원',
    thumbnail: 기본,
    visible: false,
    type: 'stock',
  },
  {
    value: '102',
    shortName: '대신저축',
    fullName: '대신저축',
    type: 'bank',
    thumbnail: 대신,
    visible: false,
  },
  {
    value: '103',
    shortName: '에스비아이저축',
    fullName: '에스비아이저축',
    type: 'bank',
    thumbnail: SBI저축은행,
    visible: false,
  },
  {
    value: '104',
    shortName: '에이치케이저축',
    fullName: '에이치케이저축',
    type: 'bank',
    thumbnail: 기본,
    visible: false,
  },
  {
    value: '105',
    shortName: '웰컴저축',
    fullName: '웰컴저축',
    type: 'bank',
    thumbnail: 기본,
    visible: false,
  },

  {
    value: '221',
    shortName: '골든브릿지투자증권',
    fullName: '골든브릿지투자증권',
    type: 'stock',
    thumbnail: 기본,
    visible: false,
  },
  {
    value: '222',
    shortName: '한양증권',
    fullName: '한양증권',
    type: 'stock',
    thumbnail: 기본,
    visible: false,
  },
  {
    value: '223',
    shortName: '리딩투자증권',
    fullName: '리딩투자증권',
    type: 'stock',
    thumbnail: 기본,
    visible: false,
  },
  {
    value: '224',
    shortName: 'BNK투자증권',
    fullName: 'BNK투자증권',
    type: 'stock',
    thumbnail: BNK투자,
    visible: false,
  },
  {
    value: '225',
    shortName: 'IBK투자증권',
    fullName: 'IBK투자증권',
    type: 'stock',
    thumbnail: IBK투자,
    visible: false,
  },

  {
    value: '247',
    shortName: 'NH투자증권',
    fullName: 'NH투자증권',
    type: 'stock',
    thumbnail: NH투자,
    visible: false,
  },

  {
    value: '265',
    shortName: '이베스트투자증권',
    fullName: '이베스트투자증권',
    type: 'stock',
    thumbnail: 이베스트,
    visible: false,
  },

  {
    value: '288',
    shortName: '카카오페이증권',
    fullName: '카카오페이증권',
    type: 'stock',
    thumbnail: 카카오페이증권,
    visible: false,
  },

  {
    value: '290',
    shortName: '부국증권',
    fullName: '부국증권',
    type: 'stock',
    thumbnail: 부국,
    visible: false,
  },
  {
    value: '291',
    shortName: '신영증권',
    fullName: '신영증권',
    type: 'stock',
    thumbnail: 신영,
    visible: false,
  },
  {
    value: '292',
    shortName: '케이프투자증권',
    fullName: '케이프투자증권',
    type: 'stock',
    thumbnail: 케이프투자,
    visible: false,
  },
  {
    value: '293',
    shortName: '한국증권금융',
    fullName: '한국증권금융',
    thumbnail: 한국포스증권,
    visible: false,
    type: 'stock',
  },
  {
    value: '294',
    shortName: '펀드온라인코리아',
    fullName: '펀드온라인코리아',
    type: 'stock',
    thumbnail: 기본,
    visible: false,
  },
  {
    value: '295',
    shortName: '우리종합금융',
    fullName: '우리종합금융',
    type: 'stock',
    thumbnail: 기본,
    visible: false,
  },
  {
    value: '299',
    shortName: '아주캐피탈',
    fullName: '아주캐피탈',
    type: 'stock',
    thumbnail: 기본,
    visible: false,
  },
] as const;

export const getBankItem = (bankCode?: string) => {
  return bankItems.find((bank) => bank.value === bankCode);
};
