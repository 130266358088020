import { z } from 'zod';

import { ReportImageSchema } from './ReportItemState';

export type MechanicReportPhotos = z.input<typeof MechanicReportPhotosSchema>;

export const MechanicReportPhotosSchema = z.object({
  exterior: z.array(ReportImageSchema).min(4).max(50),
  interior: z.array(ReportImageSchema).min(5).max(50),
  wheel: z.array(ReportImageSchema).min(4).max(50).optional(),
  carRegistration: z.array(ReportImageSchema).min(0).max(5),
  paperReport: z.array(ReportImageSchema).max(1),
  option: z.array(ReportImageSchema).min(2).max(50).optional(),
  underBody: z.array(ReportImageSchema).min(2).max(50).optional(),
  dashboard: z.array(ReportImageSchema).min(1).max(50).optional(),
  maintenanceReceipts: z.array(ReportImageSchema).optional(),
});
