import { z } from 'zod';

import { ReportItemStateSchema } from './ReportItemState';
export type FuelSystemLeakPart = z.input<typeof FuelSystemLeakPartSchema>;
export const FuelSystemLeakPartSchema = z.object({
  gasolineLeak: ReportItemStateSchema,
  dieselLeak: ReportItemStateSchema,
  lpgLeak: ReportItemStateSchema,
});
export type FuelSystemChecklist = z.input<typeof FuelSystemChecklistSchema>;
export const FuelSystemChecklistSchema = z.object({
  fuelLeak: FuelSystemLeakPartSchema,
});
