import { z } from 'zod';

import { ReportItemStateSchema } from './ReportItemState';
export type PaintPart = z.input<typeof PaintPartSchema>;
export const PaintPartSchema = z.object({
  sameColor: ReportItemStateSchema,
  differentColor: ReportItemStateSchema,
  wrapping: ReportItemStateSchema,
  ppf: ReportItemStateSchema,
});
export type PaintChecklist = z.input<typeof PaintChecklistSchema>;
export const PaintChecklistSchema = z.object({
  paint: PaintPartSchema,
});
