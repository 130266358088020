import { CarImageSchema } from '@daangn/car-types';
import { z } from 'zod';

export type DiagnosisChecklist = z.input<typeof DiagnosisChecklistSchema>;

export const DiagnosisChecklistSchema = z.object({
  engine: z.array(z.string()),
  transmission: z.array(z.string()),
  etc: z.array(z.string()),
  imgs: z.array(CarImageSchema).min(1).optional(),
  imgsAfterClear: z.array(CarImageSchema).optional(),
});
