export enum DeliveryStep {
  // 장소 입력
  Address = 'address',
  // 날짜, 시간 입력
  Date = 'date',
  // 예약 정보 확인하고 예약하기
  BankAccount = 'bankAccount',
  // 예약 성공
  PreConfirm = 'preConfirm',
}
