import type { ValueOf } from '@daangn/car-utils/type';

export const SellingDocumentQuestion = {
  ARE_YOU_BUY_A_CAR_FOR_BUSINESS: 'ARE_YOU_BUY_A_CAR_FOR_BUSINESS',
  ARE_YOU_HAVE_BUSINESS_LICENSE: 'ARE_YOU_HAVE_BUSINESS_LICENSE',
  CHOICE_BOOK_TYPE: 'CHOICE_BOOK_TYPE',
  CHOICE_INDIVIDUAL_LICENSE_TYPE: 'CHOICE_INDIVIDUAL_LICENSE_TYPE',
} as const;

export type SellingDocumentQuestion = ValueOf<typeof SellingDocumentQuestion>;

export const SellingDocumentQuestionAnswer = {
  BUSINESS_CAR: 'BUSINESS_CAR',
  CORPORATE_LICENSE: 'CORPORATE_LICENSE',
  DOUBLE_ENTRY_BOOK: 'DOUBLE_ENTRY_BOOK',
  INDIVIDUAL_CAR: 'INDIVIDUAL_CAR',
  GENERAL_TAXPAYER: 'GENERAL_TAXPAYER',
  INDIVIDUAL_LICENSE: 'INDIVIDUAL_LICENSE',
  NO_LICENSE: 'NO_LICENSE',
  SIMPLE_BOOK: 'SIMPLE_BOOK',
  SIMPLIFIED_TAXPAYER: 'SIMPLIFIED_TAXPAYER',
  'SIMPLIFIED_TAXPAYER(TAX_INVOICE)': 'SIMPLIFIED_TAXPAYER(TAX_INVOICE)',
  TAX_FREE_BUSINESS: 'TAX_FREE_BUSINESS',
} as const;

export type SellingDocumentQuestionAnswer = ValueOf<typeof SellingDocumentQuestionAnswer>;

export const sellingDocumentsQuestionsMap: Record<
  SellingDocumentQuestion,
  SellingDocumentQuestionAnswer[]
> = {
  ARE_YOU_BUY_A_CAR_FOR_BUSINESS: ['BUSINESS_CAR', 'INDIVIDUAL_CAR'],
  ARE_YOU_HAVE_BUSINESS_LICENSE: ['NO_LICENSE', 'INDIVIDUAL_LICENSE', 'CORPORATE_LICENSE'],
  CHOICE_BOOK_TYPE: ['SIMPLE_BOOK', 'DOUBLE_ENTRY_BOOK'],
  CHOICE_INDIVIDUAL_LICENSE_TYPE: [
    'SIMPLIFIED_TAXPAYER',
    'SIMPLIFIED_TAXPAYER(TAX_INVOICE)',
    'GENERAL_TAXPAYER',
    'TAX_FREE_BUSINESS',
  ],
} as const;

export const SellingDocumentQuestionAnswerText: Record<SellingDocumentQuestionAnswer, string> = {
  BUSINESS_CAR: '네',
  CORPORATE_LICENSE: '네, 법인 사업자를 가지고 있어요',
  DOUBLE_ENTRY_BOOK: '복식부기 장부',
  INDIVIDUAL_CAR: '아니요',
  GENERAL_TAXPAYER: '일반 과세자',
  INDIVIDUAL_LICENSE: '네, 개인 사업자를 가지고 있어요',
  NO_LICENSE: '아니요',
  SIMPLE_BOOK: '간편 장부',
  SIMPLIFIED_TAXPAYER: '간이 과세자',
  'SIMPLIFIED_TAXPAYER(TAX_INVOICE)': '간이 과세자(세금계산서)',
  TAX_FREE_BUSINESS: '면세 사업자',
};
