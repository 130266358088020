import type { ValueOf } from '@daangn/car-utils/type';

export const AuctionTradeProsCarExtra = {
  ADDITIONAL_PURCHASE_OF_EXTENDED_WARRANTY: 'ADDITIONAL_PURCHASE_OF_EXTENDED_WARRANTY',
  INCLUDES_BLACK_BOX: 'INCLUDES_BLACK_BOX',
  NON_SMOKE: 'NON_SMOKE',
  PERFORMANCE_TUNING: 'PERFORMANCE_TUNING',
  REPAIRS_IMPORTANT_PARTS_IN_SIX_MONTHS: 'REPAIRS_IMPORTANT_PARTS_IN_SIX_MONTHS',
  REPLACES_BRAKE_PADS_IN_SIX_MONTHS: 'REPLACES_BRAKE_PADS_IN_SIX_MONTHS',
  REPLACES_COOLANT_IN_SIX_MONTHS: 'REPLACES_COOLANT_IN_SIX_MONTHS',
  REPLACES_ENGINE_OIL_IN_SIX_MONTHS: 'REPLACES_ENGINE_OIL_IN_SIX_MONTHS',
  REPLACES_TRANSMISSION_OIL_IN_SIX_MONTHS: 'REPLACES_TRANSMISSION_OIL_IN_SIX_MONTHS',
  ROOF_BOX: 'ROOF_BOX',
  WINTER_TIRE: 'WINTER_TIRE',
} as const;

export type AuctionTradeProsCarExtra = ValueOf<typeof AuctionTradeProsCarExtra>;

export const AuctionTradeProsCarExtraText: Record<AuctionTradeProsCarExtra, string> = {
  REPLACES_BRAKE_PADS_IN_SIX_MONTHS: '브레이크 패드 교환',
  REPLACES_COOLANT_IN_SIX_MONTHS: '냉각수 교환',
  REPLACES_ENGINE_OIL_IN_SIX_MONTHS: '엔진 오일 교환',
  REPLACES_TRANSMISSION_OIL_IN_SIX_MONTHS: '미션 오일 교환',
  ADDITIONAL_PURCHASE_OF_EXTENDED_WARRANTY: '보증 연장 상품 추가 구매',
  NON_SMOKE: '금연 차량',
  PERFORMANCE_TUNING: '퍼포먼스 튜닝 (엔진, 흡배기)',
  WINTER_TIRE: '윈터 타이어',
  ROOF_BOX: '루프박스',
  REPAIRS_IMPORTANT_PARTS_IN_SIX_MONTHS: '주요 부품 수리',
  INCLUDES_BLACK_BOX: '블랙박스 포함',
};
