import { z } from 'zod';

import { CarType } from './CarType';
import { FuelType, NoDisplacementFuelTypes } from './FuelType';
import { GearType } from './GearType';

const getAutoupModelSchema = (errorMessage: string) =>
  z.object(
    {
      name: z.string(),
      id: z.string(),
      imgUrl: z.string().optional(),
    },
    { required_error: errorMessage, invalid_type_error: errorMessage }
  );

export const AutoupModelSchema = z.object({
  company: getAutoupModelSchema('제조사를 입력해 주세요'),
  series: getAutoupModelSchema('모델을 선택해 주세요'),
  subseries: getAutoupModelSchema('세부 모델을 선택해 주세요'),
  grade: getAutoupModelSchema('등급을 선택해 주세요'),
  subgrade: getAutoupModelSchema('세부 등급을 선택해 주세요').optional(),
});

export const CarDataSchema = z
  .object({
    carType: z.nativeEnum(CarType),
    carNo: z.string({
      required_error: '차량 번호를 입력해 주세요',
    }),
    modelYear: z.number({
      required_error: '연식을 선택해 주세요',
    }),
    carRegistrationYear: z.number().optional(),
    carRegistrationMonth: z.number().optional(),
    fuelType: z.nativeEnum(FuelType, {
      required_error: '연료를 선택해 주세요',
    }),
    gear: z.nativeEnum(GearType, {
      required_error: '변속기를 선택해 주세요',
    }),
    displacement: z.number({ required_error: '배기량을 입력해 주세요' }).optional(),
    autoupModel: AutoupModelSchema,
  })
  .superRefine((val, ctx) => {
    if (!NoDisplacementFuelTypes.includes(val.fuelType) && !val.displacement) {
      ctx.addIssue({
        path: ['displacement'],
        code: 'custom',
        message: '배기량을 입력해 주세요',
      });
    }
    if (val.gear === GearType.Unknown) {
      ctx.addIssue({
        path: ['gear'],
        code: 'custom',
        message: '변속기를 선택해 주세요',
      });
    }
    if (val.fuelType === FuelType.Unknown) {
      ctx.addIssue({
        path: ['fuelType'],
        code: 'custom',
        message: '연료를 선택해 주세요',
      });
    }
  });

export type AutoupModelField = z.input<ReturnType<typeof getAutoupModelSchema>>;
export type AutoupModel = z.input<typeof AutoupModelSchema>;
export type CarDataFormValue = z.input<typeof CarDataSchema>;
export const autoupFieldOrder = ['company', 'series', 'subseries', 'grade', 'subgrade'] as const;

export const defaultCarDataFormValue = {
  carNo: undefined as any,
  modelYear: undefined as any,
  carRegistrationYear: undefined as any,
  carRegistrationMonth: undefined as any,
  fuelType: undefined as any,
  gear: undefined as any,
  displacement: undefined as any,
  carType: undefined as any,
  autoupModel: {
    company: undefined as any,
    series: undefined as any,
    subseries: undefined as any,
    grade: undefined as any,
    subgrade: undefined as any,
  },
} as CarDataFormValue;
