import { safeLocalStorage, safeSessionStorage } from '@toss/storage';

type Options<TValue> = {
  defaultValue: TValue;
  storage?: 'localStorage' | 'sessionStorage';
};

export const createStorageState = <TValue>(key: string, options: Options<TValue>) => {
  const storage = options.storage === 'localStorage' ? safeLocalStorage : safeSessionStorage;
  const set = (value: NonNullable<TValue>) => storage.set(key, JSON.stringify(value));
  const get = (): TValue => {
    const result = (() => {
      const value = storage.get(key);
      if (value == null) {
        return options.defaultValue;
      }
      try {
        return JSON.parse(value);
      } catch (e) {
        console.warn('decoding failed', e);
        return value as unknown as TValue;
      }
    })();
    if (result == null && options.defaultValue != null) {
      set(options.defaultValue);
    }
    return result ?? options.defaultValue;
  };
  return {
    get,
    set,
    remove: storage.remove,
  };
};
