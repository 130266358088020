import type { ValueOf } from '@daangn/car-utils/type';

// as-is

export const CarType = {
  Small: 'SMALL',
  Middle: 'MIDDLE',
  Big: 'BIG',
  Suv: 'SUV',
  Business: 'BUSINESS',
  Etc: 'ETC',
} as const;

export const CarTypeText = {
  [CarType.Small]: '경차/소형차',
  [CarType.Middle]: '준/중형차',
  [CarType.Big]: '준/대형차',
  [CarType.Suv]: 'SUV/RV',
  [CarType.Business]: '승합/화물차',
  [CarType.Etc]: '기타',
};
export type CarType = ValueOf<typeof CarType>;
