import type { Validation } from '@daangn/car-utils/zod';

import {
  getISOWeek,
  getISOWeekYear,
  getISOWeeksInYear,
  getYear,
  setISOWeek,
  startOfISOWeek,
  startOfISOWeekYear,
} from 'date-fns';

export const tireTreadDepthDecimalPlaces = 2;
export const tireTreadDepthMax = 8;
export const brakePadWearMax = 15;

export const validateDecimalPlaces =
  (places: number) =>
  (num: number): Validation => {
    const decimalPart = num.toString().split('.')[1];
    const decimalLength = decimalPart ? decimalPart.length : 0;
    const result = decimalLength <= places;
    return result
      ? {
          isValid: true,
        }
      : {
          isValid: false,
          message: `소수점 ${places}자리까지만 입력 가능해요.`,
        };
  };

export const validateYearAndWeek = (text: string): Validation => {
  if (text.length !== 4) {
    return {
      isValid: false,
      message: `4자리를 입력해 주세요.`,
    };
  }
  if (/^\d+$/.test(text) === false) {
    return {
      isValid: false,
      message: `숫자로만 입력해 주세요.`,
    };
  }
  const weekNumber = parseInt(text.slice(0, 2));
  const yearDigits = parseInt(text.slice(tireTreadDepthDecimalPlaces));
  const currentYearLastTwoDigits = getYear(new Date()) % 100;
  const year = yearDigits >= currentYearLastTwoDigits ? 1900 + yearDigits : 2000 + yearDigits;
  const nowYear = new Date().getFullYear();
  if (year > nowYear) {
    return {
      isValid: false,
      message: `${nowYear}년까지 입력할 수 있어요.`,
    };
  }
  const maxWeekNumber = getISOWeeksInYear(new Date(year, 11, 31));
  const isOverCurrentWeekNumber = weekNumber > maxWeekNumber;
  return isOverCurrentWeekNumber
    ? {
        isValid: false,
        message: `${year}년은 ${maxWeekNumber}주까지만 있어요.`,
      }
    : { isValid: true };
};

export const toYearAndWeek = (date: Date | string) => {
  const current = new Date(date);
  const weekTwoDigits = getISOWeek(current).toString().padStart(2, '0');
  const yearTwoDigits = getISOWeekYear(current).toString().slice(2);
  return `${weekTwoDigits}${yearTwoDigits}`;
};

export const toDateStringFromYearAndWeek = (text: string): string => {
  const weekNumber = parseInt(text.substring(0, 2));
  const yearTwoDigits = parseInt(text.substring(2, text.length));
  const current = new Date();
  const currentYearLastTwoDigits = getISOWeekYear(current) % 100;
  const fullYear =
    yearTwoDigits <= currentYearLastTwoDigits ? 2000 + yearTwoDigits : 1900 + yearTwoDigits;

  return startOfISOWeek(
    setISOWeek(startOfISOWeekYear(new Date(fullYear, 1)), weekNumber)
  ).toISOString();
};
