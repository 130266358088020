import { z } from 'zod';

import { ReportItemStateSchema } from './ReportItemState';
export type ElectricalSystemGeneratorPart = z.input<typeof ElectricalSystemGeneratorPartSchema>;
export const ElectricalSystemGeneratorPartSchema = z.object({
  outputVoltage: ReportItemStateSchema,
  noise: ReportItemStateSchema,
});
export type ElectricalSystemStarterMotorPart = z.input<
  typeof ElectricalSystemStarterMotorPartSchema
>;
export const ElectricalSystemStarterMotorPartSchema = z.object({
  operation: ReportItemStateSchema,
});

export type ElectricalSystemRadiatorFanMotorPart = z.input<
  typeof ElectricalSystemRadiatorFanMotorPartSchema
>;
export const ElectricalSystemRadiatorFanMotorPartSchema = z.object({
  radiatorFanMotor: ReportItemStateSchema,
});
export type ElectricalSystemChecklist = z.input<typeof ElectricalSystemChecklistSchema>;
export const ElectricalSystemChecklistSchema = z.object({
  generator: ElectricalSystemGeneratorPartSchema,
  starterMotor: ElectricalSystemStarterMotorPartSchema,
  radiatorFanMotor: ElectricalSystemRadiatorFanMotorPartSchema,
});
