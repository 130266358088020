import { z } from 'zod';

import { ReportConsumableItemSchema, ReportItemStateSchema } from './ReportItemState';
export type SteeringOperationPart = z.input<typeof SteeringOperationPartSchema>;
export const SteeringOperationPartSchema = z.object({
  steeringLooseness: ReportItemStateSchema,
});
export type SteeringPowerOilPart = z.input<typeof SteeringPowerOilPartSchema>;
export const SteeringPowerOilPartSchema = z.object({
  level: ReportConsumableItemSchema,
});
export type SteeringOilLeakPart = z.input<typeof SteeringOilLeakPartSchema>;
export const SteeringOilLeakPartSchema = z.object({
  pump: ReportItemStateSchema,
  gearBox: ReportItemStateSchema,
  highPressureHose: ReportItemStateSchema,
});
export type TieRodAndBallJointPart = z.input<typeof TieRodAndBallJointPartSchema>;
export const TieRodAndBallJointPartSchema = z.object({
  tieRodAndBallJoint: ReportItemStateSchema,
});
export type SteeringChecklist = z.input<typeof SteeringChecklistSchema>;
export const SteeringChecklistSchema = z.object({
  operation: SteeringOperationPartSchema,
  powerOil: SteeringPowerOilPartSchema,
  oilLeak: SteeringOilLeakPartSchema,
  tieRodAndBallJoint: TieRodAndBallJointPartSchema,
});
