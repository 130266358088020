import type { ValueOf } from '@daangn/car-utils/type';

import { objectValues } from '@toss/utils';

export const SafetyOption = {
  PARK_SENSOR_FRONT: 'PARK_SENSOR_FRONT',
  PARK_SENSOR_REAR: 'PARK_SENSOR_REAR',
  REAR_CAMERA: 'REAR_CAMERA',
  AROUND_VIEW: 'AROUND_VIEW',
  LDWS: 'LDWS',
  BSD: 'BSD',
  AEB: 'AEB',
  TPMS: 'TPMS',
} as const;
export const SafetyOptionNames = objectValues(SafetyOption);
export type SafetyOption = ValueOf<typeof SafetyOption>;
