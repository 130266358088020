import { FilterPath, Nullish, Paths } from '@daangn/car-utils/type';
import { z } from 'zod';

import { AccidentHistoryChecklistSchema } from './AccidentHistoryChecklist';
import { AutoTransmissionChecklistSchema } from './AutoTransmissionChecklist';
import { BrakingSystemChecklistSchema } from './BrakingSystemChecklist';
import { DiagnosisChecklistSchema } from './DiagnosisChecklist';
import { ElectricalSystemChecklistSchema } from './ElectricalSystemChecklist';
import { EngineChecklistSchema } from './EngineChecklist';
import { ExteriorChecklistSchema } from './ExteriorChecklist';
import { FuelSystemChecklistSchema } from './FuelSystemChecklist';
import { HighVoltageElectricalSystemChecklistSchema } from './HighVoltageElectricalSystemChecklist';
import { InteriorConditionChecklistSchema } from './InteriorConditionChecklist';
import { ManualTransmissionChecklistSchema } from './ManualTransmissionChecklist';
import { MechanicReportBaseInfoSchema } from './MechanicReportBaseInfo';
import { MechanicReportPhotosSchema } from './MechanicReportPhotos';
import { OptionsChecklistSchema } from './OptionsChecklist';
import { PaintChecklistSchema } from './PaintChecklist';
import { PowertrainChecklistSchema } from './PowertrainChecklist';
import {
  type ReportItemCommentAndImages,
  ReportItemCommentAndImagesSchema,
} from './ReportItemCommentAndImages';
import { type ReportItemImage, ReportItemState } from './ReportItemState';
import { SteeringChecklistSchema } from './SteeringChecklist';
import { TireChecklistSchema } from './TireChecklist';

export const MechanicReportSchema = z.object({
  baseInfo: MechanicReportBaseInfoSchema,
  tire: TireChecklistSchema,
  accidentHistory: AccidentHistoryChecklistSchema,
  engine: EngineChecklistSchema,
  autoTransmission: AutoTransmissionChecklistSchema,
  manualTransmission: ManualTransmissionChecklistSchema,
  powertrain: PowertrainChecklistSchema,
  steering: SteeringChecklistSchema,
  brakingSystem: BrakingSystemChecklistSchema,
  electricalSystem: ElectricalSystemChecklistSchema,
  highVoltageElectricalSystem: HighVoltageElectricalSystemChecklistSchema,
  fuelSystem: FuelSystemChecklistSchema,
  paint: PaintChecklistSchema,
  exterior: ExteriorChecklistSchema,
  options: OptionsChecklistSchema,
  photos: MechanicReportPhotosSchema,
  opinion: ReportItemCommentAndImagesSchema,
  diagnosis: DiagnosisChecklistSchema,
  interiorCondition: InteriorConditionChecklistSchema,
  pros: z.string().optional(),
  notice: ReportItemCommentAndImagesSchema.optional(),
});

export type MechanicReport = z.input<typeof MechanicReportSchema>;
export type MechanicReportOutput = z.output<typeof MechanicReportSchema>;
export type MechanicReportCategory = keyof MechanicReportOutput;

type AllMechanicReportPaths = Paths<MechanicReport>;

export type MechanicReportPath = Exclude<
  AllMechanicReportPaths,
  `${NonPrimitiveMechanicReportPaths}.${string}`
>;

export type ReportItemStatePath = FilterPath<
  MechanicReport,
  AllMechanicReportPaths,
  Nullish<ReportItemState>
>;

type NonPrimitiveMechanicReportPaths = FilterPath<
  MechanicReport,
  AllMechanicReportPaths,
  Nullish<ReportItemCommentAndImages | ReportItemState>
>;

export type ReportItemValue = Nullish<
  | ReportItemCommentAndImages
  | ReportItemImage[]
  | ReportItemState
  | boolean
  | number
  | string
  | string[]
>;

export type ReportItemValuePath = Exclude<
  FilterPath<MechanicReport, AllMechanicReportPaths, ReportItemValue>,
  `${NonPrimitiveMechanicReportPaths}.${string}`
>;
