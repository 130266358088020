import { compose, pipeWithNullifyValue } from './misc';

const DEFAULT_LOCALE = 'ko-KR';
const LOCALE = navigator.language || DEFAULT_LOCALE;

/**
 * 쉼표 포맷으로 변환합니다.
 * @param {number} num - 포맷할 숫자
 * @returns {string} 쉼표가 포함된 문자열
 */
export const getCommaFormat = (num: number) => {
  return Number(num ?? 0).toLocaleString(LOCALE);
};

/**
 * 문자열에 'km'를 추가합니다.
 * @param {string} str - 입력 문자열
 * @returns {string} 'km'가 추가된 문자열
 */
export const getKmFormat = (str: string) => {
  return str + 'km';
};

/**
 * 문자열에 '원'을 추가합니다.
 * @param {string} str - 입력 문자열
 * @returns {string} '원'이 추가된 문자열
 */
export const getWonFormat = (str: string) => {
  return str + '원';
};

/**
 * 숫자를 쉼표 포맷으로 변환하고 'km'를 추가합니다.
 */
export const getCommaKmFormat = pipeWithNullifyValue(getCommaFormat, getKmFormat);

/**
 * 숫자를 쉼표 포맷으로 변환하고 '원'을 추가합니다.
 */
export const getCommaWonFormat = pipeWithNullifyValue(getCommaFormat, getWonFormat);

export const NUM_1만 = 10000;
export const NUM_1억 = Math.pow(NUM_1만, 2);

/**
 * 만원 단위로 저장된 값을 변환합니다. (만 단위).
 * @param {number} price - 변환할 가격 (만 단위)
 * @returns {number} 정확한 가격 (만 단위로 곱해진 값)
 */
export const getExactPrice = (price: number) => {
  if (!price) {
    return 0;
  }

  return Number(price) * NUM_1만;
};

/**
 * 가격을 정확한 값으로 변환하고 쉼표 포맷을 적용합니다.
 */
export const getPriceFormat = compose(getCommaFormat, getExactPrice);

/**
 * 숫자를 억, 만 단위로 포맷합니다.
 * @param {number} number - 포맷할 숫자
 * @returns {string} 억, 만 단위로 포맷된 문자열
 */
export const getBillionFormat = (number: number) => {
  const result = [];
  const floorAndComma = compose(getCommaFormat, Math.floor);

  if (!number) {
    return '0';
  }

  if (number >= NUM_1억) {
    result.push(`${floorAndComma(number / NUM_1억)}억`);
    number %= NUM_1억;
  }

  if (number > 0) {
    result.push(`${floorAndComma(number / NUM_1만)}만`);
  }

  return result.join(' ');
};

/**
 * 가격을 정확한 값으로 변환하고 억, 만 단위로 포맷합니다.
 */
export const get억만FromPrice = (price: number) => {
  return compose(getBillionFormat, getExactPrice)(price);
};

/**
 * 숫자가 주어진 범위 내에 있는지 확인합니다.
 * @param {number} num - 확인할 숫자
 * @param {number} min - 최소값
 * @param {number} max - 최대값
 * @returns {boolean} 범위 내에 있으면 true, 아니면 false
 */
export const isInRange = (num: number, min: number, max: number) => min <= num && num <= max;

/**
 * 숫자 배열의 합을 계산합니다.
 * @param {number[]} arr - 숫자 배열
 * @returns {number} 배열의 합
 */
export const sum = (arr: number[]) => arr.reduce((total, curr) => total + curr, 0);

/**
 * 숫자를 주어진 범위 내로 제한합니다.
 * @param {number} num - 제한할 숫자
 * @param {number} min - 최소값
 * @param {number} max - 최대값
 * @returns {number} 범위 내로 제한된 숫자
 */
export const clamp = (num: number, min: number, max: number) => {
  return Math.min(Math.max(min, num), max);
};

/**
 * 숫자 배열의 평균을 계산합니다.
 * @param {number[]} arr - 숫자 배열
 * @returns {number} 배열의 평균
 */
export const average = (arr: number[]) => sum(arr) / arr.length;

/**
 * 연도의 마지막 두 자리를 반환합니다.
 * @param {number} year - 연도
 * @returns {string} 연도의 마지막 두 자리
 */
export const getShortenYear = (year: number) => {
  return year.toString().slice(-2);
};

/**
 * 부동소수점 숫자를 포맷합니다.
 * @param {number | null | undefined} floatNumber - 포맷할 부동소수점 숫자
 * @returns {string} 포맷된 문자열
 */
export const formatFloat = (floatNumber?: null | number) => {
  if (floatNumber == null) {
    return '';
  }
  const trimmed = floatNumber.toFixed(1);

  return trimmed.endsWith('.0') ? trimmed.slice(0, trimmed.length - 2) : trimmed;
};

/**
 * 대한민국의 전화번호 형식이 유효한지 확인합니다.
 * [대한민국의 전화번호 체계](https://ko.wikipedia.org/wiki/%EB%8C%80%ED%95%9C%EB%AF%BC%EA%B5%AD%EC%9D%98_%EC%A0%84%ED%99%94%EB%B2%88%ED%98%B8_%EC%B2%B4%EA%B3%84)
 * @param {string} v - 확인할 전화번호
 * @returns {boolean} 유효한 형식이면 true, 아니면 false
 */
export const isValidKoreaNumber = (v = '') => {
  const seoulNoRegex = /02\-?[0-9]{3,4}\-?[0-9]{4}$/;
  const otherNoRegex = /0[3-6][1-5]\-?[0-9]{3,4}\-?[0-9]{4}$/;
  const phoneNoRegex = /010\-?[0-9]{3,4}\-?[0-9]{4}$/;
  return seoulNoRegex.test(v) || otherNoRegex.test(v) || phoneNoRegex.test(v);
};
