import { z } from 'zod';

import { defaultChecklist } from './defaultMechanicReportFormValue';
import { ReportItemStateSchema } from './ReportItemState';
export type BrakingSystemOperationPart = z.input<typeof BrakingSystemOperationPartSchema>;
export const BrakingSystemOperationPartSchema = z.object({
  vacuumBrakeBooster: ReportItemStateSchema,
});
export type BrakingSystemOilLeakPart = z.input<typeof BrakingSystemOilLeakPartSchema>;
export const BrakingSystemOilLeakPartSchema = z.object({
  masterCylinder: ReportItemStateSchema,
  caliper: ReportItemStateSchema,
});
export type BrakingSystemBrakeFluidPart = z.input<typeof BrakingSystemBrakeFluidPartSchema>;
export const BrakingSystemBrakeFluidPartSchema = z.object({
  brakeFluid: ReportItemStateSchema,
});

export type BrakingSystemSuspensionPart = z.input<typeof BrakingSystemSuspensionPartSchema>;
export const BrakingSystemSuspensionPartSchema = z.object({
  suspension: ReportItemStateSchema,
});

export type BrakingSystemChecklist = z.input<typeof BrakingSystemChecklistSchema>;
export const BrakingSystemChecklistSchema = z.object({
  operation: BrakingSystemOperationPartSchema,
  oilLeak: BrakingSystemOilLeakPartSchema,
  brakeFluid: BrakingSystemBrakeFluidPartSchema,
  suspension: BrakingSystemSuspensionPartSchema.optional().default({
    suspension: defaultChecklist,
  }),
});
