import { datadogRum } from '@datadog/browser-rum-slim';

import { IS_PROD } from '@/constants/config';

try {
  if (IS_PROD) {
    datadogRum.init({
      applicationId: 'b51db7db-ac1b-47d5-bd01-c2c8b89d3cd1',
      clientToken: 'pub815119a201c36ee64df5696da4d04617',
      defaultPrivacyLevel: 'mask-user-input',
      env: process.env.WEBVIEW_STAGE,
      service: 'car-webview',
      sessionReplaySampleRate: 0,
      sessionSampleRate: 0,
      site: 'datadoghq.com',
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      version: process.env.WEBVIEW_RELEASE_TAG!,
    });
  }
} catch {}
