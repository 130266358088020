import { z } from 'zod';

import { ReportConsumableItemSchema, ReportItemStateSchema } from './ReportItemState';

export type AutoTransmissionOperationPart = z.input<typeof AutoTransmissionOperationPartSchema>;
export const AutoTransmissionOperationPartSchema = z.object({
  PRND: ReportItemStateSchema,
  DNRP: ReportItemStateSchema,
});
export type MissionOilPart = z.input<typeof MissionOilPartSchema>;
export const MissionOilPartSchema = z.object({
  level: ReportConsumableItemSchema,
  condition: ReportConsumableItemSchema,
});
export type MissionOilLeakPart = z.input<typeof MissionOilLeakPartSchema>;
export const MissionOilLeakPartSchema = z.object({
  oilPan: ReportItemStateSchema,
  missionCase: ReportItemStateSchema,
  pump: ReportItemStateSchema,
});
export type AutoTransmissionChecklist = z.input<typeof AutoTransmissionChecklistSchema>;
export const AutoTransmissionChecklistSchema = z.object({
  operation: AutoTransmissionOperationPartSchema,
  missionOil: MissionOilPartSchema,
  oilLeak: MissionOilLeakPartSchema,
});
