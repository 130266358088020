import { z } from 'zod';

import { CarImageSizeSchema } from './CarImageSize';

export const CarImageSchema = z
  .object({
    id: z.string().min(1),
    url: z.string().min(1),
  })
  .merge(CarImageSizeSchema)
  .transform((v) => {
    const { id, url } = v;
    return { id, url };
  });

export type CarImage = z.input<typeof CarImageSchema>;
